/// Exporting the utils funcitons for user details screen
export const userDetailsUtils = {
  formatTimestamp,
  calculateBMR,
  getStartAndEndDate,
};

//// Funciton for formattting the timestamp -------------------------------------/
function formatTimestamp(timestamp: string) {
  if (!timestamp) return "";

  // Convert the UTC timestamp to a Date object
  const dateObject = new Date(timestamp);

  // Format the date part
  const date = dateObject.toLocaleDateString("en-US", { timeZone: "UTC" });

  // Format the time part
  const time = dateObject.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  // Combine the date with the hour and minute in UTC time
  return date + " " + time;
}

//// Function for calculating the Bmr ----------------------------------------------/
async function calculateBMR(
  weight: number,
  height: number,
  yob: number,
  gender: string
) {
  let bmr = 0;
  let age = new Date().getFullYear() - yob;

  if (gender === "Male") {
    bmr = 5 + 10 * weight + 6.25 * height - 5 * age;
  } else if (gender === "Female") {
    bmr = -161 + 10 * weight + 6.25 * height - 5 * age;
  }

  return bmr;
}

/// Funciton for getting the end date and start date -----------------------------/
async function getStartAndEndDate(week: any[]) {
  return {
    starting: week[0].date,
    ending: week[week.length - 1].date,
  };
}
