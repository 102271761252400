/** @format */

import { Dialog, Container, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Snackbar } from "@mui/material";

// Import necessary components from MUI
import CloseIcon from "@mui/icons-material/Close"; // Make sure CloseIcon is imported
import { NutritionImageModalInterface } from "../interface/NutritionLogInterface";
export default function NutritionImageModal({
  isPopupOpen,
  setIsPopupOpen,
  changeModalData,
  isVideo,
  nutritionData,
  selectedNutritionDataIndex,
}: NutritionImageModalInterface) {
  console.log("this is nutriondata");
  console.log(nutritionData);

  return (
    <Dialog
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      PaperProps={{
        style: {
          background: "rgba(255, 255, 255, 0.9)", // Semi-transparent white background
          borderRadius: "15px", // Rounded corners for the modal
          overflow: "hidden", // Ensures the overlay does not exceed the modal's rounded corners
        },
      }}
      fullWidth={true} // Ensures the dialog uses the full width of its container
      maxWidth="md" // Adjust this to control the maximum width of the modal
    >
      <div
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsPopupOpen(false)}
      >
        <CloseIcon style={{ color: "black" }} />
      </div>
      {nutritionData ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px", // Adds some padding inside the container
          }}
        >
          <IconButton
            onClick={() => changeModalData("prev", selectedNutritionDataIndex)}
            style={{ marginRight: "20px" }}
          >
            <ArrowBackIos />
          </IconButton>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: "20px", // Adds space between the image/video and the data
            }}
          >
            {isVideo ? (
              <video
                src={nutritionData.imageUrl}
                style={{ width: "100%", height: "auto", borderRadius: "10px" }} // Adds rounded corners to the video
                controls
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={nutritionData.imageUrl}
                style={{ width: "100%", margin: "auto", borderRadius: "10px" }} // Adds rounded corners to the image
                alt="Nutritional content"
              />
            )}
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              backgroundColor: "white", // White background for the data section
              padding: "20px", // Internal padding for the data section
              borderRadius: "10px", // Rounded corners for the data section
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow around the data section
            }}
          >
            {/* Stylish presentation of data using Typography */}

            <Typography variant="subtitle1">
              Type: {nutritionData.type}
            </Typography>
            <Typography variant="subtitle1">
              Status: {nutritionData.status}
            </Typography>
            <Typography variant="subtitle1">
              Error Message: {nutritionData.error_msg}
            </Typography>

            <Typography variant="subtitle1">
              Created At:{" "}
              {nutritionData.createdAt
                ? nutritionData.createdAt.toLocaleString()
                : "N/A"}
            </Typography>
            {nutritionData.rawOutput ? (
              <Typography variant="subtitle1">
                Raw Output: {nutritionData.rawOutput}
              </Typography>
            ) : null}
          </div>
          <IconButton
            onClick={() => changeModalData("next", selectedNutritionDataIndex)}
            style={{ marginLeft: "20px" }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Container>
      ) : null}
    </Dialog>
  );
}
