import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material"; // Import cross icon

import { additionalService } from "../../services/additional.service";

const InformationForm = ({
  onClose,
  onSave,
  campaignId,
  campaignName,
  initialData, // Add initialData prop
}: {
  onClose: () => void;
  onSave: (data: any) => void;
  campaignId: string | null;
  campaignName: string | null;
  initialData?: any; // Make initialData optional
}) => {
  const [title, setTitle] = useState(initialData?.input_fields?.title || "");
  const [body, setBody] = useState(initialData?.input_fields?.body || "");
  const [image, setImage] = useState<File | null>(null);
  const [imageName, setImageName] = useState<string | null>(null); // New state for image name
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setImageName(initialData.input_fields.imageUrl.split("/").pop() || ""); // Extract the image name from the URL
    }
  }, [initialData]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
      setImageName(e.target.files[0].name); // Set image name
    }
  };

  const handleImageClear = () => {
    setImage(null);
    setImageName(null); // Clear image name
  };

  const handleSubmit = async () => {
    setLoading(true);
    let imageUrl = initialData?.input_fields?.imageUrl || null; // Use existing image URL if no new image is uploaded
    try {
      if (image) {
        // If there is a new image, upload it first
        imageUrl = await additionalService.uploadImageOnAzure(image);
      }

      console.log("this is image url");
      console.log(imageUrl);

      // Save the interaction with the updated or new data
      onSave({
        type: "information",
        input_fields: {
          title,
          body,
          imageUrl,
        },
      });

      // Close the dialog or modal
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle the error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Information Form
      </Typography>
      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          id="image-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="contained" component="span">
            Browse Image
          </Button>
        </label>
        {imageName && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {imageName}
            </Typography>
            <IconButton onClick={handleImageClear} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        sx={{ mb: 2 }}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {initialData ? "Update" : "Add"}{" "}
          {/* Change button text based on mode */}
        </Button>
      )}
    </Box>
  );
};

export default InformationForm;
