/** @format */

import { Typography, Box, Container } from "@mui/material";

import TopAppBar from "../modules/TopAppBar";
import Learn from "../Components/AppSettings/Learn";
import SubscriptionSettings from "../Components/AppSettings/SubscriptionSettings";
import OldVersion from "../Components/AppSettings/OldVersion";
import NewVersion from "../Components/AppSettings/NewVersion";
import PromptSettings from "../Components/AppSettings/PromptSettings";
import { useState } from "react";
import OnboardingConfig from "../Components/AppSettings/OnboardingConfig";
import InAppReview from "../Components/AppSettings/InAppReview";

const AppSettings = () => {
  const [releaseNote, setReleaseNote] = useState({ android: "", ios: "" });
  return (
    <div>
      <TopAppBar />
      <OldVersion releaseNote={releaseNote} setReleaseNote={setReleaseNote} />
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      ></Container>
      <NewVersion releaseNote={releaseNote} setReleaseNote={setReleaseNote} />
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      ></Container>
      <Box
        p={4}
        style={{ backgroundColor: "#f0f0f0" }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
            Subscription Settings
          </Typography>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SubscriptionSettings />
        </Container>
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      ></Container>

      <PromptSettings />
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      ></Container>

      <Learn />
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      ></Container>
      <OnboardingConfig />
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          In App Review
        </Typography>
      </Container>
      <InAppReview />
    </div>
  );
};

export default AppSettings;
