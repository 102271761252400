import { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IndividualWorkouts from "../modules/IndividualWorkoutDetails";
import TrainerWorkouts from "../modules/TrainerWorkoutDetails";
import WorkoutPlanModal from "./WorkoutModal";
interface WorkoutHistory {
  selectedUser: any;
  loading: boolean;
  workout: any;
}

export default function WorkoutHistory({
  selectedUser,
  loading,
  workout,
}: WorkoutHistory) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [ownWorkout, setOwnWorkout] = useState<any[]>([]);
  const [trainerWorkout, setTrainerWorkout] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  function divideWorkout(workout: any) {
    setOwnWorkout(workout.workout);
    console.log("this are trainer workout");

    console.log(workout.trainerWorkout);

    setTrainerWorkout(workout.trainerWorkout);
  }

  useEffect(() => {
    // console.log("this useeffect is called");

    // if (workout.length > 0) {
    //   console.log("did not went here");

    divideWorkout(workout);
    //}
  }, []);

  return (
    <>
      <WorkoutPlanModal
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        userId={selectedUser._id}
      />

      <Container>
        {loading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 10 }} />
          </Container>
        ) : (
          <Container>
            <TableContainer
              component={Paper}
              sx={{ marginTop: 2, height: "65vh", overflowX: "hidden" }}
            >
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "50%", textAlign: "center" }}>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Typography
                            style={{ fontWeight: "600", fontSize: 17 }}
                          >
                            Workouts
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button onClick={handleOpen}>Add Plan</Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {trainerWorkout && trainerWorkout.length > 0 && (
                  <>
                    <TableBody>
                      {trainerWorkout.map((row: any, index: number) => (
                        <>
                          <Typography
                            style={{
                              marginLeft: 15,
                              marginTop: 10,
                              textDecoration: "underline",
                              fontWeight: "bold",
                            }}
                          >
                            {row.planName}
                          </Typography>
                          <TrainerWorkouts
                            workoutData={row}
                            currentDate={currentDate}
                            isLast={index === trainerWorkout.length - 1}
                          />
                        </>
                      ))}
                    </TableBody>
                  </>
                )}
                {ownWorkout && ownWorkout.length > 0 && (
                  <>
                    <Typography
                      style={{
                        marginLeft: 15,
                        marginTop: 10,
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      Others
                    </Typography>
                    <TableBody>
                      {ownWorkout.map((row: any) => (
                        <IndividualWorkouts
                          workoutData={row}
                          currentDate={currentDate}
                        />
                      ))}
                    </TableBody>
                  </>
                )}

                {workout &&
                  workout.length === 0 &&
                  trainerWorkout.length === 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography style={{ marginTop: 10 }}>
                        No Workout History available
                      </Typography>
                    </div>
                  )}
              </Table>
            </TableContainer>
          </Container>
        )}
      </Container>
    </>
  );
}
