import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
const baseUrl = `${config.apiUrl}/api`;
export const campaignService = {
  createNewCampaign,
  getAllCampaign,
  addAudiences,
  deleteCampaign,
  getAllAudiences,
  createDuplicateCampaign,
};

/// Function For adding new campagins ------------------------------------/
async function createNewCampaign(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/create-campaign`, {
    ...data,
  });
}
/// Function For adding duplicate campagins ------------------------------------/
async function createDuplicateCampaign(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/create-duplicatecampaign`, {
    ...data,
  });
}
// Funciton for deleting the campaign -----------------------------------/
async function deleteCampaign(campaignId: string) {
  return fetchWrapper.delete(
    `${baseUrl}/delete-campaign?campaignId=${campaignId}`
  );
}

async function addAudiences(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/add-audiences`, {
    data,
  });
}
/// Function For adding new campagins ------------------------------------/
async function getAllCampaign(): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(`${baseUrl}/get-campaigns`);
}

//// Funciton for getting all the audiences of an campaign -----------------/
async function getAllAudiences(campaign_id: string): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-audiences?campaign_id=${campaign_id}`
  );
}
