import { CardContent, CircularProgress, Typography } from "@mui/material";
import { memo } from "react";
import { Interface } from "readline";
import { boolean } from "yup";

interface UserAppActivity {
  loading: boolean;
  meals: any[];
  mealData: any;
  latestActivityName: string;
}

/// Function For Showing The Last Activity -------------------------------/
function showActivity(key: any, value: any) {
  if (key == "id" || key == "apiId") {
    return null;
  } else if (key === "dateTime") {
    const dateObj = new Date(value);
    const formattedDate = `${dateObj.toLocaleString("default", {
      month: "long",
    })} ${dateObj.getDate()}, ${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${dateObj.getSeconds().toString().padStart(2, "0")}`;
    return (
      <Typography sx={{ mb: 1.5 }} color="text.secondary" key={key}>
        {key}: {formattedDate}
      </Typography>
    );
  } else {
    return (
      <Typography sx={{ mb: 1.5 }} color="text.secondary" key={key}>
        {key}: {value}
      </Typography>
    );
  }
}

//// Main funciton for the User App Activity --------------------------------------------/
function UserAppActivity({
  loading,
  meals,
  mealData,
  latestActivityName,
}: UserAppActivity) {
  return (
    <CardContent>
      <Typography sx={{ fontSize: 20 }} color="#000" gutterBottom>
        App Activity
      </Typography>

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ height: 5, width: 7 }} />
        </div>
      ) : (
        <>
          {meals.length === 0 ? null : (
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",

                marginBottom: 5,
              }}
            >
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                style={{
                  fontWeight: "700",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                {latestActivityName}
              </Typography>
              {mealData ? (
                Object.entries(mealData).map(([key, value]) =>
                  showActivity(key, value)
                )
              ) : (
                <p>Loading...</p>
              )}
            </CardContent>
          )}
        </>
      )}
    </CardContent>
  );
}

export default memo(UserAppActivity);
