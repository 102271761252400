/** @format */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import {
  WeightBrowseDataInterface,
  WeightDataInterface,
} from "../../interface/WeightInterface";

function WeightBrwoseData({ weightArray }: WeightBrowseDataInterface) {
  console.log("this is weightarray");
  console.log(weightArray);

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: 2,
        height: "65vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              Date Time
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Weight
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {weightArray?.map((row: WeightDataInterface, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Typography style={{ textAlign: "center" }}>
                  {row.dateTime}
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography style={{ textAlign: "center" }}>
                  {row.weight}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(WeightBrwoseData);
