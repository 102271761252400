import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material"; // Import cross icon
import { additionalService } from "../../services/additional.service";

interface FreeTextFormProps {
  onClose: () => void;
  onSave: (data: {
    type: string;
    title: string;
    body?: string;
    imageUrl?: string | null;
    inputBoxPlaceholder?: string;
  }) => void;
  initialData?: {
    input_fields: {
      title: string;
      body?: string;
      imageUrl?: string | null;
      inputBoxPlaceholder?: string;
    };
  };
}

const FreeTextForm: React.FC<FreeTextFormProps> = ({
  onClose,
  onSave,
  initialData,
}) => {
  const [title, setTitle] = useState(initialData?.input_fields?.title || "");
  const [body, setBody] = useState(initialData?.input_fields?.body || "");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [inputBoxPlaceholder, setInputBoxPlaceholder] = useState(
    initialData?.input_fields?.inputBoxPlaceholder || ""
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState<string | null>(null);

  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setImageName(initialData.input_fields.imageUrl.split("/").pop() || ""); // Extract the image name from the URL
    }
  }, [initialData]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImageFile(e.target.files[0]);
      setImageName(e.target.files[0].name); // Set image name
    }
  };

  const handleSubmit = async () => {
    if (!title) {
      setError("Title is required.");
      return;
    }

    setLoading(true);
    let imageUrl = initialData?.input_fields?.imageUrl || null;

    try {
      if (imageFile) {
        // If there is a new image, upload it first
        imageUrl = await additionalService.uploadImageOnAzure(imageFile);
      }

      // Save the interaction locally or perform other actions
      onSave({
        type: "free_text",
        title,
        body,
        imageUrl, // Use uploaded URL or the existing imageUrl
        inputBoxPlaceholder: inputBoxPlaceholder || undefined,
      });

      // Close the dialog or modal
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle the error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  const handleImageClear = () => {
    setImageFile(null);
    setImageName(null); // Clear image name
  };

  return (
    <Box sx={{ width: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Free Text Form
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          if (error) setError(null); // Clear error when user starts typing
        }}
      />
      <TextField
        label="Body"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        sx={{ mb: 2 }}
        value={body}
        placeholder={inputBoxPlaceholder}
        onChange={(e) => {
          setBody(e.target.value);
          if (error) setError(null); // Clear error when user starts typing
        }}
      />
      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          id="image-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="contained" component="span">
            Browse Image
          </Button>
        </label>
        {imageName && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {imageName}
            </Typography>
            <IconButton onClick={handleImageClear} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <TextField
        label="Body Input Placeholder"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={inputBoxPlaceholder}
        onChange={(e) => setInputBoxPlaceholder(e.target.value)}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {initialData ? "Update" : "Add"}{" "}
            {/* Change button text based on mode */}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FreeTextForm;
