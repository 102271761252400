/** @format */

import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BedIcon from "@mui/icons-material/Bed"; // Import the BedIcon
import BedtimeIcon from "@mui/icons-material/Bedtime"; // Import the BedtimeIcon
import {
  SleepByDateInterface,
  SleepInterface,
} from "../../interface/SleepInterface";
import { sleepUtilsFunction } from "../../utils/Sleep";
export default function StepsByDate({
  selectedUser,
  sleep,
  loading,
  currentDate,
  setCurrentDate,
}: SleepByDateInterface) {
  console.log(sleep);

  const [currentSleep, setCurrentSleep] = useState<SleepInterface[]>([]);

  const isAndroid = selectedUser.metadata.os.toLowerCase().includes("android");

  //// Function for calling the sleeputility function to get currentday sleep-----/
  async function getCurrentDaySteps(sleepData: any, currentDate: Date) {
    const sleepArrayResponse = await sleepUtilsFunction.getCurrentDaySleep(
      sleepData,
      currentDate
    );
    setCurrentSleep(sleepArrayResponse);
  }

  useEffect(() => {
    if (sleep.length > 0) {
      getCurrentDaySteps(sleep, currentDate);
    } else {
      setCurrentSleep([]);
    }
  }, [sleep, selectedUser]);

  const increaseDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
    getCurrentDaySteps(sleep, newDate);
  };

  const decreaseDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    getCurrentDaySteps(sleep, newDate);
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
        }}
      >
        <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={decreaseDate} />
        <Typography style={{ textAlign: "center", fontWeight: "800" }}>
          {currentDate.toDateString()}
        </Typography>
        <ArrowForwardIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (currentDate.getDate() === new Date().getDate()) {
              return;
            } else {
              increaseDate();
            }
          }}
        />
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Container
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "65vh",
            overflowY: "scroll",
            marginTop: 10,
          }}
        >
          {currentSleep.length > 0 ? (
            <Container
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              {currentSleep.map((sleep: SleepInterface, index: number) => (
                <Paper
                  elevation={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 30,
                    width: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: 1,
                    }}
                  >
                    <BedtimeIcon
                      sx={{ marginRight: "5px", fontSize: "15", marginLeft: 1 }}
                    />
                    <Typography
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      {currentDate.toDateString()}
                    </Typography>
                  </Box>
                  <Divider />
                  {isAndroid && sleep.sleep_session !== null && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: 10,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <BedIcon />
                        <Typography style={{ marginLeft: 5 }}>
                          Sleep Session:
                        </Typography>
                      </div>
                      <Typography
                        style={{ fontWeight: "bold", marginRight: 10 }}
                      >
                        {sleepUtilsFunction.formatSleepSession(
                          sleep.sleep_session
                        )}
                      </Typography>
                    </div>
                  )}
                  {!isAndroid && sleep.sleep_in_bed !== null && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: 10,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <BedIcon />
                        <Typography style={{ marginLeft: 5 }}>
                          In Bed:
                        </Typography>
                      </div>
                      <Typography
                        style={{ fontWeight: "bold", marginRight: 10 }}
                      >
                        {sleepUtilsFunction.formatSleepSession(
                          sleep.sleep_in_bed
                        )}
                      </Typography>
                    </div>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginTop: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "95%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AccessTimeIcon
                          sx={{ marginRight: "5px", fontSize: "small" }}
                        />
                        <Typography>Start Time:</Typography>
                      </div>
                      <Typography style={{ fontWeight: "bold" }}>
                        {sleepUtilsFunction.formatTime(sleep.start_dt)}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "95%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AccessTimeIcon
                          sx={{ marginRight: "5px", fontSize: "small" }}
                        />
                        <Typography>End Time:</Typography>
                      </div>
                      <Typography style={{ fontWeight: "bold" }}>
                        {sleepUtilsFunction.formatTime(sleep.end_dt)}
                      </Typography>
                    </div>
                  </Box>
                </Paper>
              ))}
            </Container>
          ) : (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ marginTop: 50 }}>
                No Sleep Session available
              </Typography>
            </Container>
          )}
        </Container>
      )}
    </>
  );
}
