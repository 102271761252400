import { memo, useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  Collapse,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Input,
  Tooltip,
} from "@mui/material";
import { accountService } from "../services";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React from "react";
import {
  FilterInterface,
  FilterSettings,
  SortingModalInterface,
} from "../interface/FilterInterface";
import {
  checkBoxFilterObject,
  FilterInitialState,
  initialFilterSettings,
  isFilterName,
} from "../utils/FilterUtis";
import * as Papa from "papaparse";

const CustomInputStarDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);
const CustomInputDateRangeStartDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="Start Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);
const CustomInputDateRangeEndDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="End Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);

//// Main Function for the Sorting Modal -------------------------------------------------------------------/
function SortingModal({
  modalOpen,
  setModalOpen,
  totalUsers,
  setTotalUsers,
  setNumberOfCalls,
  listInnerRef,
  setTotalNumberOfUsers,
  setUserLoading,
  resetCheckBoxFunctionaltiy,
  setHasMoreUsers,
  filters,
  setFilters,
  fetchUsers,
  countries,
  selectedUserIds,
}: SortingModalInterface) {
  /// State for the filters settings ( like checkbox turn on off )---------------------------------/
  const [filterSettings, setFilterSettings] = useState<FilterSettings>(
    initialFilterSettings
  );
  //const [allUsers, setAllUsers] = useState([]); // This should hold the original full list of users
  const [iconActive, setIconActive] = useState("");

  const [csvFieldName, setCsvFieldName] = useState("id"); // State to hold the field name

  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  const [isDatePickerDisabled, setDatePickerDisabled] = useState(false);
  const [isToggleButtonGroupDisabled, setToggleButtonGroupDisabled] =
    useState(false);
  const [isDateRangeDisabled, setDateRangeDisabled] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function for updating the filter settings
  const updateFilterSettings = (
    key: keyof FilterSettings,
    value: boolean | undefined
  ) => {
    setFilterSettings((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateFilterState = (key: keyof FilterInterface, value: any) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleToggleButtonChange = (
    event: any,
    newValue: any,
    filterName: string
  ) => {
    if (!isFilterName(filterName)) {
      console.error(`Invalid filter name: ${filterName}`);
      return;
    }
    const filterKeys = checkBoxFilterObject[filterName];

    if (newValue === filterName) {
      if (!filterSettings[filterKeys.checkBox]) {
        updateFilterSettings(filterKeys.checkBox, true);
      }
      updateFilterState(filterKeys.filter, true);
    } else if (newValue === null) {
      updateFilterState(filterKeys.filter, false);
    }
  };

  // Handler for checkbox change
  const handleCheckbox = (
    event: any,
    filterName: keyof FilterInterface,
    checkBoxKey: keyof FilterSettings
  ) => {
    const checked = event.target.checked;
    console.log(checked);

    if (!checked) {
      updateFilterState(filterName, undefined);
      updateFilterSettings(checkBoxKey, false);
    } else {
      console.log("went in true");
      updateFilterState(filterName, checked);
      updateFilterSettings(checkBoxKey, checked);
    }
  };

  const handleSort = (order: any) => {
    const sortedUsers = [...totalUsers].sort((a, b) => {
      const dateA = new Date(a.lastActive).getTime();
      const dateB = new Date(b.lastActive).getTime();

      // For ascending order: earliest lastActive first, latest lastActive last

      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setIconActive(order);
    setTotalUsers(sortedUsers);
  };
  const handleClearDate = () => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { activityType: "", date: null },
      dateRange: { startDate: null, endDate: null },
    }));
    setToggleButtonGroupDisabled(false);
    setDateRangeDisabled(false);
  };

  const handleDateChange = (date: Date | null) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { ...prevState.activity, date },
    }));
    if (date) {
      setToggleButtonGroupDisabled(true);
      setDateRangeDisabled(true);
    } else {
      setToggleButtonGroupDisabled(false);
      setDateRangeDisabled(false);
    }
  };

  const handleDateRangeChange = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    const adjustedStartDate = startDate
      ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
      : null;
    const adjustedEndDate = endDate
      ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
      : null;

    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      dateRange: { startDate: adjustedStartDate, endDate: adjustedEndDate },
    }));
    if (adjustedStartDate || adjustedEndDate) {
      setDatePickerDisabled(true);
      setToggleButtonGroupDisabled(true);
    } else {
      setDatePickerDisabled(false);
      setToggleButtonGroupDisabled(false);
    }
  };

  const handleActivityTypeChange = (event: any) => {
    const value = event.target.value;
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { ...prevState.activity, activityType: value },
    }));
    if (value) {
      setToggleButtonGroupDisabled(true);
      setDateRangeDisabled(true);
    } else {
      setToggleButtonGroupDisabled(false);
      setDateRangeDisabled(false);
    }
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null
  ) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activeType: newType,
      activity: { ...prevState.activity, date: null, activityType: "" },
    }));
    if (newType) {
      setDatePickerDisabled(true);
    } else {
      setDatePickerDisabled(false);
    }
  };

  // Function to construct API call parameters based on active filters and make the call
  const fetchFilteredUsers = async (filters: FilterInterface) => {
    // Construct the parameters based on active
    setUserLoading(true);

    const user = await accountService.userValue;
    let numberofCalls = 1;

    const params = {
      numberofCalls: numberofCalls,
      userGroupNumber: 20,
      deviceType: filters.deviceType, // Add device type if active
      onboarding: filters.onboardingFilter, // Add onboarding status if active
      subscription: filters.subscriptionFilter, // Add subscription status if active
      trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
      countries: filters.checkBoxCountries, // Include selected countries in the request
      notification: filters.notificationFilter,
      maxVersion: filters.maxVersion,
      minVersion: filters.minVersion,
      genderType: filters.genderType,
      goalType: filters.goalType,
      activeType: filters.activeType,
      activity: filters.activity,
      dateRange: filters.dateRange,
      contactNumber: filters.contactNumberFilter,
      notes: filters.notesFilter,
      uncheckedCountries: filters.uncheckCountries,
      messageSeen: filters.messageSeenFilter,
      countryCode: filters.countryCode,
      csvFile: filters.csvFileFilter,
      distinctIds: filters.distinctIds,
      openSupportMessage: filters.openSupportMessage,
      minimumAge: filters.minimumAge,
      maximumAge: filters.maximumAge,
      maximumBmi: filters.maximumBmi,
      minimumBmi: filters.minimumBmi,
    };

    if (listInnerRef.current) {
      listInnerRef.current.scrollTop = 0;
    }
    try {
      const res = await accountService.getAllUsers(params);

      setHasMoreUsers(true);
      let { users, userCount } = res;
      if (users.length > 0) {
        setTotalUsers(users);
        setTotalNumberOfUsers(userCount);
        if (userCount < 20) {
          setHasMoreUsers(false);
        }
      } else {
        setTotalUsers([]);
        setTotalNumberOfUsers(0);

        setHasMoreUsers(false);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setTotalUsers([]);

      // Handle error state appropriately
    }

    setNumberOfCalls(1);
    setUserLoading(false);
    resetCheckBoxFunctionaltiy();
  };

  const handleCountryChange = (
    country: string,
    actionType: "checkbox" | "cross"
  ) => {
    setFilters((prevFilters: any) => {
      const { checkBoxCountries, uncheckCountries } = prevFilters;
      const isInCheckBox = checkBoxCountries.includes(country);
      const isInUncheck = uncheckCountries.includes(country);

      if (actionType === "checkbox") {
        if (isInUncheck) {
          alert("Please unselect the country first.");
          return prevFilters;
        }

        if (!isInCheckBox) {
          // Add to checkbox countries if not already present
          const updatedCheckBox = [...checkBoxCountries, country];
          const updatedUncheck = uncheckCountries.filter(
            (c: any) => c !== country
          );
          return {
            ...prevFilters,
            checkBoxCountries: updatedCheckBox,
            uncheckCountries: updatedUncheck,
          };
        } else {
          // Remove from checkbox countries if already present
          const updatedCheckBox = checkBoxCountries.filter(
            (c: any) => c !== country
          );
          return { ...prevFilters, checkBoxCountries: updatedCheckBox };
        }
      } else if (actionType === "cross") {
        if (isInCheckBox) {
          alert("Please unselect the country first.");
          return prevFilters;
        }

        if (!isInUncheck) {
          // Add to uncheck countries if not already present
          const updatedUncheck = [...uncheckCountries, country];
          const updatedCheckBox = checkBoxCountries.filter(
            (c: any) => c !== country
          );
          return {
            ...prevFilters,
            checkBoxCountries: updatedCheckBox,
            uncheckCountries: updatedUncheck,
          };
        } else {
          // Remove from uncheck countries if already present
          const updatedUncheck = uncheckCountries.filter(
            (c: any) => c !== country
          );
          return { ...prevFilters, uncheckCountries: updatedUncheck };
        }
      }

      return prevFilters;
    });
  };

  function reseting() {
    //  setHasMoreUsers(true);
    setUserLoading(true);
    handleCloseModal();
    setFilterSettings(initialFilterSettings);
    setNumberOfCalls(1);
    setFilters(FilterInitialState);
    fetchUsers();
  }

  function applyFilter() {
    fetchFilteredUsers(filters);
    handleCloseModal();
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          console.log("Parsed CSV file:", results);
          const distinctIdsArray = results.data
            .map((row: any) => row[csvFieldName as keyof typeof row])
            .filter(Boolean);

          console.log("District IDs array:", distinctIdsArray);
          setFilters((prevFilters: any) => ({
            ...prevFilters,
            distinctIds: distinctIdsArray,
          }));
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
        },
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "78%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        <Typography
          id="modal-title"
          style={{ fontWeight: "800", marginBottom: 3 }}
        >
          Sort Options
        </Typography>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
              padding: 1,
              marginY: 1,
              width: "92%",
            }}
          >
            <IconButton
              color={iconActive === "asc" ? "secondary" : "primary"}
              onClick={() => handleSort("asc")}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Typography sx={{ mx: 1 }}>Last Activity</Typography>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <IconButton
              color={iconActive === "desc" ? "secondary" : "primary"}
              onClick={() => handleSort("desc")}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Activity</h3>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                mb: 2,
              }}
            >
              <IconButton
                onClick={handleClearDate}
                disabled={isDatePickerDisabled}
                aria-label="clear date"
              >
                <ClearIcon />
              </IconButton>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {isDatePickerDisabled ? (
                  <TextField
                    label=" Date"
                    value=""
                    disabled
                    sx={{ width: "100%", marginRight: 3 }}
                  />
                ) : (
                  <DatePicker
                    selected={filters.activity.date}
                    onChange={handleDateChange}
                    customInput={<CustomInputStarDate />}
                  />
                )}
              </LocalizationProvider>

              <FormControl sx={{ minWidth: "40%" }}>
                <InputLabel id="activity-type-label">Activity Type</InputLabel>
                <Select
                  labelId="activity-type-label"
                  id="activity-type"
                  value={filters.activity.activityType}
                  onChange={handleActivityTypeChange}
                  label="Activity Type"
                  disabled={isDatePickerDisabled}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={filters.activeType}
                exclusive
                onChange={handleToggleChange}
                aria-label="Filter by type"
                sx={{ width: "100%" }}
                disabled={isToggleButtonGroupDisabled}
              >
                <ToggleButton value="7" sx={{ width: "50%" }}>
                  7 days
                </ToggleButton>
                <ToggleButton value="30" sx={{ width: "50%" }}>
                  30 days
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Typography>Date Range</Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                mb: 2,
              }}
            >
              <IconButton
                onClick={() => handleDateRangeChange(null, null)}
                disabled={isDateRangeDisabled}
                aria-label="clear date range"
              >
                <ClearIcon />
              </IconButton>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  selected={filters.dateRange.startDate}
                  onChange={(date: Date | null) =>
                    handleDateRangeChange(date, filters.dateRange.endDate)
                  }
                  customInput={<CustomInputDateRangeStartDate />}
                  disabled={isDateRangeDisabled}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  selected={filters.dateRange.endDate}
                  onChange={(date: Date | null) =>
                    handleDateRangeChange(filters.dateRange.startDate, date)
                  }
                  customInput={<CustomInputDateRangeEndDate />}
                  disabled={isDateRangeDisabled}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.maxVersion}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  maxVersion: e.target.value,
                }))
              }
              placeholder="Max version"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.minVersion}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  minVersion: e.target.value,
                }))
              }
              placeholder="Min version"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.maximumBmi}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  maximumBmi: e.target.value,
                }))
              }
              placeholder="Max Bmi"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.minimumBmi}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  minimumBmi: e.target.value,
                }))
              }
              placeholder="Min Bmi"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.maximumAge}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  maximumAge: e.target.value,
                }))
              }
              placeholder="Maximum Age"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={filters.minimumAge}
              onChange={(e) =>
                setFilters((prevFilters: FilterInterface) => ({
                  ...prevFilters,
                  minimumAge: e.target.value,
                }))
              }
              placeholder="Minimum Age"
              style={{
                width: "92%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                margin: "5px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>
          <ToggleButtonGroup
            color="primary"
            value={filters.deviceType}
            exclusive
            onChange={(event, newType) =>
              setFilters((prevFilters: any) => ({
                ...prevFilters,
                deviceType: newType,
              }))
            } // Use an anonymous function to pass the new type
            aria-label="Filter by type"
            sx={{ width: "100%" }} // This makes the entire button group take full width of its container
          >
            <ToggleButton value="ios" sx={{ width: "50%" }}>
              Ios
            </ToggleButton>
            <ToggleButton value="android" sx={{ width: "50%" }}>
              Android
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            color="primary"
            value={filters.genderType} // Assuming genderType is a key in the filters state
            exclusive
            onChange={(event, newType) =>
              setFilters((prevFilters: any) => ({
                ...prevFilters,
                genderType: newType,
              }))
            }
            aria-label="Filter by type"
            sx={{ width: "100%" }} // This makes the entire button group take full width of its container
          >
            <ToggleButton value="Male" sx={{ width: "50%" }}>
              Male
            </ToggleButton>
            <ToggleButton value="Female" sx={{ width: "50%" }}>
              Female
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            color="primary"
            value={filters.goalType} // Assuming goalType is a key in the filters state
            onChange={(event, newTypes) =>
              setFilters((prevFilters: any) => ({
                ...prevFilters,
                goalType: newTypes.length ? newTypes : [],
              }))
            }
            aria-label="Filter by type"
            sx={{ width: "100%" }} // This makes the entire button group take full width of its container
          >
            <ToggleButton
              value="Lose Weight and Gain Muscle"
              sx={{ width: "25%", fontSize: 12 }}
            >
              Lose Weight and Gain Muscle
            </ToggleButton>
            <ToggleButton
              value="Lose Weight"
              sx={{ width: "25%", fontSize: 12 }}
            >
              Lose Weight
            </ToggleButton>
            <ToggleButton
              value="Lifestyle Improvements"
              sx={{ width: "25%", fontSize: 12 }}
            >
              Lifestyle Improvements
            </ToggleButton>
            <ToggleButton
              value="Gain Muscle"
              sx={{ width: "25%", fontSize: 12 }}
            >
              Gain Muscle
            </ToggleButton>
          </ToggleButtonGroup>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.subscriptionCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "subscriptionFilter",
                  "subscriptionCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.subscriptionFilter ? "subscription" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "subscription");
              }}
              aria-label="Filter by subscription"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="subscription" sx={{ width: "100%" }}>
                Subscription
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              ignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.openSupportMessageCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "openSupportMessage",
                  "openSupportMessageCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.openSupportMessage ? "openSupportMessage" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "openSupportMessage");
              }}
              aria-label="Filter by openSupportMessage"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="openSupportMessage" sx={{ width: "100%" }}>
                Open Support Message
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.notesCheckBox}
              onChange={(event) =>
                handleCheckbox(event, "notesFilter", "notesCheckBox")
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.notesFilter ? "notes" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "notes");
              }}
              aria-label="Filter by notes"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="notes" sx={{ width: "100%" }}>
                Notes
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.contactNumberCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "contactNumberFilter",
                  "contactNumberCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.contactNumberFilter ? "contactNumber" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "contactNumber");
              }}
              aria-label="Filter by contactNumber"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="contactNumber" sx={{ width: "100%" }}>
                Contact Number
              </ToggleButton>
            </ToggleButtonGroup>
            {filters.contactNumberFilter != undefined && (
              <TextField
                label="Country Code"
                value={filters.countryCode}
                onChange={(e) =>
                  setFilters((prevFilters: FilterInterface) => ({
                    ...prevFilters,
                    countryCode: e.target.value,
                  }))
                }
                sx={{ ml: 1 }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.csvFileCheckBox}
              onChange={(event) =>
                handleCheckbox(event, "csvFileFilter", "csvFileCheckBox")
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.csvFileFilter ? "csvFile" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "csvFile");
              }}
              aria-label="Filter by csvFile"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="csvFile" sx={{ width: "100%" }}>
                CSV Filter
              </ToggleButton>
            </ToggleButtonGroup>
            {filters.csvFileFilter != undefined && (
              <>
                <TextField
                  label="Field Name"
                  value={csvFieldName}
                  onChange={(e) => setCsvFieldName(e.target.value)} // Update the field name state
                  variant="outlined"
                  margin="normal"
                  sx={{ mb: 2 }}
                />
                <Input
                  type="file"
                  onChange={handleFileUpload}
                  sx={{ display: "none" }}
                  id="csv-file-input"
                  inputProps={{ accept: ".csv" }} // Use inputProps to pass the accept attribute
                />
                <label htmlFor="csv-file-input">
                  <Tooltip title="Make sure to set the correct field name before uploading. Changing it afterward won't affect the results.">
                    <Button variant="contained" component="span" sx={{ ml: 1 }}>
                      Select CSV File
                    </Button>
                  </Tooltip>
                </label>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.onboardingCheckBox}
              onChange={(event) =>
                handleCheckbox(event, "onboardingFilter", "onboardingCheckBox")
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.onboardingFilter ? "onboarding" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "onboarding");
              }}
              aria-label="Filter by onboarding"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="onboarding" sx={{ width: "100%" }}>
                Onboarding
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.notificationCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "notificationFilter",
                  "notificationCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.notificationFilter ? "notification" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "notification");
              }}
              aria-label="Filter by notification"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="notification" sx={{ width: "100%" }}>
                Notification
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.messageSeenCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "messageSeenFilter",
                  "messageSeenCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.messageSeenFilter ? "messageSeen" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "messageSeen");
              }}
              aria-label="Filter by messageSeen"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="messageSeen" sx={{ width: "100%" }}>
                Message Seen
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Country Filter */}

          <ToggleButton
            value="check"
            selected={countryDropdownOpen}
            onChange={() => setCountryDropdownOpen(!countryDropdownOpen)}
            sx={{ width: "100%" }}
          >
            Countries
            {countryDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ToggleButton>

          {/* Collapsible Country Dropdown */}

          <Collapse in={countryDropdownOpen}>
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "scroll",
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                padding: 1,
                marginY: 1,
                width: "100%", // Adjust this to take more width
                backgroundColor: "#f0f0f0",
              }}
            >
              <FormControl component="fieldset">
                <FormGroup>
                  {countries.map((country: any, index: any) => {
                    const isChecked =
                      filters.checkBoxCountries.includes(country);
                    const isCrossed =
                      filters.uncheckCountries.includes(country);

                    return (
                      <Box key={index} display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={() =>
                                handleCountryChange(country, "checkbox")
                              }
                              value={country}
                              style={{
                                backgroundColor: isChecked
                                  ? "skyblue"
                                  : "transparent",
                              }}
                            />
                          }
                          label={country}
                        />
                        <IconButton
                          onClick={() => handleCountryChange(country, "cross")}
                          aria-label={`toggle ${country}`}
                          style={{
                            color: isCrossed ? "red" : "black",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Box>
          </Collapse>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ToggleButton
              onClick={applyFilter}
              value="onboarding"
              sx={{ width: "50%" }}
            >
              Apply
            </ToggleButton>
            <ToggleButton
              value="reset"
              sx={{ width: "50%" }}
              onClick={() => reseting()}
            >
              Reset
            </ToggleButton>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default memo(SortingModal);
