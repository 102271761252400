//// Initial value for the targetValues--------------------------------/
const initialTargetValue = {
  steps: 0,
  weights: 0,
  protien: 0,
  calorie: 0,
  sleep: 0,
  water: 0,
};

export { initialTargetValue };
