/** @format */

import { config } from "../shared/constants/config";

import { fetchWrapper } from "../helpers";
const baseUrl = `${config.apiUrl}/api/users`;
export const userService = {
  getWeekFromDate,
  getCurrentWeight,
  getWorkoutHistory,
  getWorkoutByDate,
  fetchClientDetails,
  getActivities,
  getWeight,
  setOnboardingDate,
  getSearchUsersOfAdmin,
  getSearchUsersOfTrainer,
  getTotalData,
};

function getDayName(date: any) {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return dayNames[date.getDay()];
}
function getDayOfWeek(dateString: string) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(dateString);
  const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  return daysOfWeek[dayOfWeek];
}
function getName(date: any) {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return dayNames[date.getDay()];
}
export async function getWeekFromDate(date: any) {
  const endDate = new Date(date); // Today's date

  const weekArray = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = new Date(endDate);
    currentDay.setDate(endDate.getDate() - i);
    const offset = currentDay.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(currentDay.getTime() - offset);
    weekArray.unshift({
      // Using unshift to prepend the current day to the beginning of the array
      date: adjustedDate.toISOString().split("T")[0],
      day: getName(currentDay),
    });
  }

  return weekArray;
}

export async function filterLastSevenDaysWithDayNames(
  data: any[],
  dataType: "steps" | "water",
  weekArray: any[]
) {
  const result = [];

  // Add day and date key-value pair in data
  data?.forEach((item: any) => {
    const date = item.dateTime.split(" ")[0]; // Extract date portion (YYYY-MM-DD)
    const day = getDayOfWeek(date);
    item.day = day;
    item.date = date;
  });

  if (data) {
    // Create a map to store the latest dataType for each unique date and day
    const dataMap = new Map();

    // Iterate over the input data and store the latest dataType for each date and day
    for (const itemData of data) {
      const key = `${itemData.date}_${itemData.day}`;

      if (
        !dataMap.has(key) ||
        new Date(itemData.dateTime) > new Date(dataMap.get(key).dateTime)
      ) {
        dataMap.set(key, itemData);
      }
    }

    // Iterate over the weekArray and build the result array
    for (const dateObj of weekArray) {
      const key = `${dateObj.date}_${dateObj.day}`;
      const latestData = dataMap.get(key);
      const totalData = latestData ? latestData[dataType] : 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        [dataType]: totalData,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        [dataType]: 0,
      });
    }
  }

  return result;
}

/// Function for calculating 7 day sleep sessions--------------------------/
export async function filterLastSevenDaysSleepSessions(
  data: any[],
  weekArray: any[]
) {
  const result = [];

  // Add day and date key-value pair in data
  data?.forEach((item: any) => {
    const date = item.dateTime.split(" ")[0]; // Extract date portion (YYYY-MM-DD)
    const day = getDayOfWeek(date);
    item.day = day;
    item.date = date;
  });

  if (data) {
    // Create a map to store the total sleep sessions for each unique date and day
    const sleepSessionsMap = new Map();

    // Iterate over the input data and calculate total sleep sessions for each date and day
    for (const itemData of data) {
      const key = `${itemData.date}_${itemData.day}`;

      if (sleepSessionsMap.has(key)) {
        sleepSessionsMap.set(
          key,
          sleepSessionsMap.get(key) + itemData.sleep_session
        );
      } else {
        sleepSessionsMap.set(key, itemData.sleep_session);
      }
    }

    // Iterate over the weekArray and build the result array
    for (const dateObj of weekArray) {
      const key = `${dateObj.date}_${dateObj.day}`;
      const totalSleepSessions = sleepSessionsMap.get(key) || 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        sleep_session: totalSleepSessions,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        sleep_session: 0,
      });
    }
  }

  return result;
}

/// Function for calulating 7 days maxium step based ondate ---------------------/
export async function filterLastSevenDaysForSteps(
  data: any[],
  weekArray: any[]
) {
  const result = [];
  if (data) {
    const dataMap = new Map();

    for (const itemData of data) {
      const date = itemData.dateTime.split(" ")[0];
      dataMap.set(date, itemData.steps);
    }

    for (const dateObj of weekArray) {
      const value = dataMap.get(dateObj.date) || 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        steps: value,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        steps: 0,
      });
    }
  }

  return result;
}

/// Function For Getting 7 days total sleep -----------------------------------------------------/
export async function getWeeklySleepData(
  data: any,
  weekArray: any,
  selectedUser: any
) {
  const result = [];
  if (data) {
    const dataMap = new Map();

    for (const itemData of data) {
      const date = itemData.dateTime.split(" ")[0];
      // Check if the current entry has a non-null sleep value based on the OS
      const sleepValue = selectedUser.metadata.os
        .toLowerCase()
        .includes("android")
        ? itemData.sleep_session
        : itemData.sleep_in_bed;
      if (sleepValue !== null) {
        // Check if there's already an entry for this date
        if (dataMap.has(date)) {
          // If there's already an entry, aggregate the sleep value
          const existingValue = dataMap.get(date);
          // Aggregate the sleep value based on the OS
          existingValue.sleep += sleepValue;
        } else {
          // If there's no existing entry, add the current one
          dataMap.set(date, {
            dateTime: itemData.dateTime, // Store the date-time of the latest entry for reference
            sleep: sleepValue, // Initialize sleep value
          });
        }
      }
    }

    for (const dateObj of weekArray) {
      const value = dataMap.get(dateObj.date);
      // If there's no entry for the date or if the entry's sleep value is null, set sleep to 0
      const sleep = value ? value.sleep : 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        sleep: sleep,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        sleep: 0,
      });
    }
  }

  return result;
}

/// Function For Getting 7 days total water value with date--------------------------------------/
export async function filterLastSevenDaysWithMaxWaterValue(
  data: any[],
  weekArray: any[]
) {
  const result = [];

  if (data) {
    // Create a map to store the maximum water for each unique date
    const dataMap = new Map();

    // Iterate over the input data and calculate the maximum water for each date
    for (const itemData of data) {
      const date = itemData.dateTime.split(" ")[0]; // Extract date portion (YYYY-MM-DD)
      const water = itemData.water;

      if (dataMap.has(date)) {
        dataMap.set(date, Math.max(dataMap.get(date), water));
      } else {
        dataMap.set(date, water);
      }
    }

    // Iterate over the weekArray and build the result array
    for (const dateObj of weekArray) {
      const maxWater = dataMap.get(dateObj.date) || 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        water: maxWater,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        water: 0,
      });
    }
  }
  return result;
}

//// Function for getting the weekarray data of weights----------------------------/
export async function weekArrayDataOfWeights(data: any[], weekArray: any[]) {
  const result = [];
  if (data) {
    const dataMap = new Map();

    for (const itemData of data) {
      const date = itemData.dateTime.split(" ")[0];
      dataMap.set(date, itemData.weight);
    }

    for (const dateObj of weekArray) {
      const value = dataMap.get(dateObj.date) || 0;
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        weights: value,
      });
    }
  } else {
    // If there's no input data, populate with all zeros
    for (const dateObj of weekArray) {
      result.push({
        date: dateObj.date,
        day: dateObj.day,
        weights: 0,
      });
    }
  }

  return result;
}

export async function calculateTotalCalorieAndProteinForWeekIndividually(
  meals: any[],
  returnType: string,
  weekArray: any[]
) {
  try {
    const result = [];

    if (meals) {
      // Initialize an object to store the totals
      const totals: Record<
        string,
        { totalCalorie: number; totalProtein: number }
      > = {};
      // Filtering out meals with null mealName
      meals = meals.filter(
        (meal) => meal.mealName !== null && meal.mealName !== ""
      );

      // Iterate over the input meals
      for (const meal of meals) {
        let date = "";

        if (meal.dateTime) {
          /// In the case with new schema so dateTime is like this Meal.dateTime-----------/

          date = meal.dateTime.split(" ")[0];
        } else {
          /// In The Case When Schema is old and dateTime is inside ingredients-------------/

          if (meal.ingredients && meal.ingredients.length !== 0) {
            /// When ingredients is not empty so when date will be takes---------------------/

            if (meal.ingredients[0].dateTime) {
              date = meal.ingredients[0].dateTime.split(" ")[0];
            } else {
              date = "";
            }
          } else {
            /// When ingredients is empty so date won't be takes----------------------------/
            date = "";
          }
        }

        let calorie = 0;
        let protein = 0;
        if (meal.mealName) {
          calorie = meal.calorie;
          protein = meal.protein;
        } else {
          //// For new shcema protein and calorie is inside the new schema so where it is inside ingredients--/
          for (let i = 0; i < meal.ingredients.length; i++) {
            calorie += meal.ingredients[i].calories;
            protein += meal.ingredients[i].protein;
          }
        }

        // Check if the meal date is within the last seven days
        const matchingDateObj = weekArray.find((dateObj) =>
          date.startsWith(dateObj.date)
        );

        if (matchingDateObj) {
          // If the date matches, update the totals
          if (!totals[matchingDateObj.date]) {
            totals[matchingDateObj.date] = {
              totalCalorie: 0,
              totalProtein: 0,
            };
          }
          totals[matchingDateObj.date].totalCalorie += calorie;
          totals[matchingDateObj.date].totalProtein += protein;
        }
      }

      // Convert totals object to result array based on the returnType parameter
      for (const dateObj of weekArray) {
        const total = totals[dateObj.date] || {
          totalCalorie: 0,
          totalProtein: 0,
        };

        let totalValue =
          returnType === "protein" ? total.totalProtein : total.totalCalorie;

        // Remove decimal part
        totalValue = Math.floor(totalValue); // or Math.round(totalValue) if you prefer

        const resultObj = {
          date: dateObj.date,
          day: dateObj.day,
          total: totalValue,
        };
        result.push(resultObj);
      }
    } else {
      // If there's no input meals data, populate with all zeros
      for (const dateObj of weekArray) {
        const total = returnType === "protein" ? 0 : 0;
        const resultObj = {
          date: dateObj.date,
          day: dateObj.day,
          total: total,
        };
        result.push(resultObj);
      }
    }

    return result;
  } catch (error) {
    throw new Error("Unable to proccedd further");
  }
}

/// Function For Getting All The Meals------------------------------------------/
export async function getAllMeals(userId: string): Promise<any[]> {
  try {
    return fetchWrapper.post(`${config.apiUrl}/api/meals`, {
      userId,
    });
  } catch (error) {
    return [];
  }
}
/// Function For Handeling the calculating the total calorie and protien --/

export async function calculateTotalCalorieAndProteinForWeekNew(
  meals: any[],

  weekArray: any[]
) {
  const result = [];
  try {
    if (meals) {
      // Initialize an object to store the totals
      const totals: Record<
        string,
        {
          totalCalorie: number;
          totalProtein: number;
          totalCarbohydrates: number;
          totalFat: number;
          totalPolyUnsaturatedFat: number;
          totalMonoUnsaturatedFat: number;
          totalSaturatedFat: number;
          totalTransFat: number;
          totalFiber: number;
          totalSugar: number;
          meals: {
            mealName: string;
            ingredients: string[];
            singleMeal: string[];
            image: string;
          }[];
        }
      > = {};

      // Iterate over the input meals
      for (const meal of meals) {
        let date = "";

        if (meal.dateTime) {
          date = meal.dateTime.split(" ")[0];
        } else {
          if (meal.ingredients[0]?.dateTime)
            date = meal.ingredients[0].dateTime.split(" ")[0];
        }

        // If dateTime is missing, skip processing for this meal
        if (!date) {
          continue;
        }

        // Initialize meal-level totals
        let mealCalorie = 0;
        let mealProtein = 0;
        let mealCarbohydrates = 0;
        let mealFat = 0;
        let mealPolyUnsaturatedFat = 0;
        let mealMonoUnsaturatedFat = 0;
        let mealSaturatedFat = 0;
        let mealTransFat = 0;
        let mealFiber = 0;
        let mealSugar = 0;
        // Iterate through the ingredients of the meal\
        if (meal.ingredients) {
          /// In The Case the meal is a complete one so the main meal has ingredients--------------/
          for (const ingredient of meal.ingredients) {
            mealCalorie += ingredient.calories || 0;
            mealProtein += ingredient.protein || 0;
            mealCarbohydrates += ingredient.carbohydrates || 0;
            mealFat += ingredient.fats || 0;
            mealMonoUnsaturatedFat += ingredient.mono_unsaturated_fat || 0;
            mealPolyUnsaturatedFat +=
              ingredient.polyUnsaturatedFat_unsaturated_fat || 0;
            mealSaturatedFat += ingredient.saturated_fat || 0;
            mealTransFat += ingredient.trans_fat || 0;
            mealFiber += ingredient.fiber || 0;
            mealSugar += ingredient.sugar || 0;
          }
        } else {
          /// In the case the food itself is a meal so do not have any ingredients-----------------/
          mealCalorie += meal.calorie || 0;
          mealProtein += meal.protein || 0;
          mealCarbohydrates += meal.carbohydrates || 0;
          mealFat += meal.fats || 0;
          mealMonoUnsaturatedFat += meal.mono_unsaturated_fat || 0;
          mealPolyUnsaturatedFat +=
            meal.polyUnsaturatedFat_unsaturated_fat || 0;
          mealSaturatedFat += meal.saturated_fat || 0;
          mealTransFat += meal.trans_fat || 0;
          mealFiber += meal.fiber || 0;
          mealSugar += meal.sugar || 0;
        }

        // Check if the meal date is within the last seven days
        for (const dateObj of weekArray) {
          if (date.startsWith(dateObj.date)) {
            // If the date matches, update the totals
            if (!totals[dateObj.date]) {
              totals[dateObj.date] = {
                totalCalorie: 0,
                totalProtein: 0,
                totalCarbohydrates: 0,
                totalFat: 0,
                totalMonoUnsaturatedFat: 0,
                totalPolyUnsaturatedFat: 0,
                totalSaturatedFat: 0,
                totalTransFat: 0,
                totalFiber: 0,
                totalSugar: 0,
                meals: [],
              };
            }
            totals[dateObj.date].totalCalorie += mealCalorie;
            totals[dateObj.date].totalProtein += mealProtein;
            totals[dateObj.date].totalCarbohydrates += mealCarbohydrates;
            totals[dateObj.date].totalFat += mealFat;
            totals[dateObj.date].totalMonoUnsaturatedFat +=
              mealMonoUnsaturatedFat;
            totals[dateObj.date].totalPolyUnsaturatedFat +=
              mealPolyUnsaturatedFat;
            totals[dateObj.date].totalSaturatedFat += mealSaturatedFat;
            totals[dateObj.date].totalTransFat += mealTransFat;
            totals[dateObj.date].totalFiber += mealFiber;
            totals[dateObj.date].totalSugar += mealSugar;
            totals[dateObj.date].meals.push({
              mealName: meal.name ? meal.name : meal.mealName,
              ingredients: meal.ingredients ? meal.ingredients : [],
              singleMeal: meal.ingredients ? [] : meal,
              image: meal.image,
            });
          }
        }
      }

      // Convert totals object to result array based on the returnType parameter
      for (const dateObj of weekArray) {
        const total = totals[dateObj.date] || {
          totalCalorie: 0,
          totalProtein: 0,
          totalCarbohydrates: 0,
          totalFat: 0,
          totalMonoUnsaturatedFat: 0,
          totalPolyUnsaturatedFat: 0,
          totalSaturatedFat: 0,
          totalTransFat: 0,
          totalFiber: 0,
          totalSugar: 0,
          meals: [],
        };
        const resultObj = {
          date: dateObj.date,
          day: dateObj.day,
          totalCalorie: total.totalCalorie,
          totalProtein: total.totalProtein,
          totalCarbohydrates: total.totalCarbohydrates,
          totalFat: total.totalFat,
          totalMonoSaturatedFat: total.totalMonoUnsaturatedFat,
          totalPolySaturatedFat: total.totalPolyUnsaturatedFat,
          totalSaturatedFat: total.totalSaturatedFat,
          totalTransFat: total.totalTransFat,
          totalFiber: total.totalFiber,
          totalSugar: total.totalSugar,

          meals: total.meals,
        };
        result.push(resultObj);
      }
    } else {
      // If there's no input meals data, populate with all zeros and empty arrays
      for (const dateObj of weekArray) {
        const resultObj = {
          date: dateObj.date,
          day: dateObj.day,
          totalProtien: 0,
          totalCalorie: 0,
          totalCarbohydrates: 0,
          totalFat: 0,
          totalMonoSaturatedFat: 0,
          totalPolySaturatedFat: 0,
          totalSaturatedFat: 0,
          totalTransFat: 0,
          totalFiber: 0,
          totalSugar: 0,
          meals: [],
        };
        result.push(resultObj);
      }
    }
  } catch (e) {}

  return result;
}

/// Function For Getting The exact day data of protien ,calorie,steps and water---------/

export const extractNutritionDataForDate = async (
  totals: any,
  date: any,
  stepsData: any,
  waterData: any,
  sleepData: any
) => {
  const offset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  const adjustedDate = new Date(date.getTime() - offset);
  const dateString = adjustedDate.toISOString().split("T")[0];

  // Extract Protein and calorie  Data which are matching
  let matchedData = totals?.find((item: any) => item.date === dateString);

  // // Extract Water and steps  Data
  const stepsFinalData = stepsData.find(
    (item: any) => item.date === dateString
  );
  const waterFinalData = waterData.find(
    (item: any) => item.date === dateString
  );
  const sleepFinalData = sleepData.find(
    (item: any) => item.date === dateString
  );

  let mealArray = matchedData?.meals.map((meal: any) => {
    return {
      image: meal.image,
      mealName: meal.mealName,
      singleMealData: meal.singleMeal,
      ingredients: meal.ingredients.map((ingredient: any) => {
        return {
          name: ingredient.name,
          quantity: ingredient.quantity,
          calories: ingredient.calories,
          protein: ingredient.protein,
          carbohydrates: ingredient.carbohydrates,
          fat: ingredient.fats,
          fiber: ingredient.fiber,
          sugar: ingredient.sugar,
          unit: ingredient.unit,
        };
      }),
    };
  });
  return {
    matchedData,
    mealArray,
    dateString,
    stepsFinalData,
    waterFinalData,
    sleepFinalData,
  };
};

/// Function for calculating the total protien and calorie from a meal ingridients---------/
export const individualMealCalculation = async (ingridients: any) => {
  if (ingridients) {
    let totalProtein = 0;
    let totalCalorie = 0;
    let totalQuantity = 0;
    for (let i = 0; i < ingridients.length; i++) {
      totalQuantity += ingridients[i].quantity;
      totalCalorie += ingridients[i].calories;
      totalProtein += ingridients[i].protein;
    }

    return {
      totalCalorie: totalCalorie,
      totalProtein: totalProtein,
      totalQuantity: totalQuantity,
    };
  } else {
    return { totalCalorie: 0, totalProtein: 0, totalQuantity: 0 };
  }
};
// function uint8ArrayToBase64(buffer: any) {
//   let binary = "";
//   const bytes = new Uint8Array(buffer);
//   const len = bytes.byteLength;
//   for (let i = 0; i < len; i++) {
//     binary += String.fromCharCode(bytes[i]);
//   }
//   return window.btoa(binary);
// }
// function base64ToUint8Array(base64: any) {
//   var raw = window.atob(base64);
//   var rawLength = raw.length;
//   var array = new Uint8Array(new ArrayBuffer(rawLength));

//   for (let i = 0; i < rawLength; i++) {
//     array[i] = raw.charCodeAt(i);
//   }
//   return array;
// }
// /// Function For Getting All the Meals-----------------------------------------------------------/
// export async function getUserMeals() {
//   try {
//     var xhrLocal = new XMLHttpRequest();
//     // Retrieve the Base64 string from localStorage
//     // Retrieve the Base64 string from localStorage and ensure it's not null
//     const base64String = localStorage.getItem("databaseFile");

//     if (base64String) {
//       // Decode the Base64 string to a binary string
//       const binaryString = atob(base64String);

//       // Create a new ArrayBuffer with the same length as the binary string
//       const bufferLength = binaryString.length;
//       const buffer = new ArrayBuffer(bufferLength);

//       // Create a new Uint8Array view referencing the buffer
//       const arrayBufferView = new Uint8Array(buffer);

//       const SQL = await initSqlJs({
//         locateFile: () => "/public/sql-wasm.wasm",
//       });
//       const db = new SQL.Database();
//       console.log(db);

//       console.log("this is sql");
//       console.log(SQL);

//       // Iterate over each character in the binary string and populate the array buffer with the char codes
//       // for (let i = 0; i < bufferLength; i++) {
//       //   arrayBufferView[i] = binaryString.charCodeAt(i);
//       // }
//       // console.log(arrayBufferView);

//       // // Load the sql-wasm.wasm file (you may need to configure the file path based on your project structure)
//       // const SQL = await initSqlJs({
//       //   locateFile: (file) => "./sql-wasm.wasm",
//       // });
//     } else {
//       console.log("localstorage is empty");
//     }
//   } catch (error) {
//     console.error("Error running queries on the database:", error);
//   }
// }

// /// Function For Fetching The Main sql Database from the azure storage --------------------------/
// async function fetchDatabase(id: any) {
//   const sasToken =
//     "sv=2022-11-02&se=2099-12-31T00%3A00%3A00Z&sr=c&sp=rwd&sig=ZfLn%2BTMBhRrw7KH4Sd3yKRBM1d%2BYFE4JsRTHU4RAt3I%3D";
//   try {
//     // Fetch the database file as a buffer from the server
//     const response = await axios({
//       method: "GET",
//       url: `/user-data/${id}/${id}.db?${sasToken}`,
//       responseType: "arraybuffer",
//     });
//     const base64String = uint8ArrayToBase64(response.data);

//     // Store the Base64 string in localStorage
//     localStorage.setItem("databaseFile", base64String);
//   } catch (error) {
//     console.error("Error downloading the database:", error);
//   }
// }

/// Function For Getting The Current Weight of the User----------------------------/
async function getCurrentWeight(id: any) {
  return fetchWrapper.get(`${baseUrl}/currentweight?id=${id}
  `);
}

/// Function For Getting The Workout History of the User----------------------------/
async function getWorkoutHistory(id: any) {
  return fetchWrapper.get(`${baseUrl}/workout?id=${id}
  `);
}
/// Function For Getting The By Date workout data of the User----------------------------/
async function getWorkoutByDate(id: any) {
  return fetchWrapper.get(`${baseUrl}/bydate/workout?id=${id}
  `);
}
/// Funciton for getting the client details---------------------------/
async function fetchClientDetails(trainerId: any, date: any) {
  return fetchWrapper.get(
    config.apiUrl +
      `/api/clientdetails?trainerId=${trainerId}&dateInput=${date}`
  );
}

/// Function For getting The user activities----------------------------/
async function getActivities(id: any) {
  return fetchWrapper.get(`${baseUrl}/activity?id=${id}
  `);
}

/// Function For getting The u----------------------------/
async function getWeight(id: any) {
  return fetchWrapper.get(`${baseUrl}/get-weight?id=${id}
  `);
}

/// Function For adding the onboarding date into user table--------------/
async function setOnboardingDate(id: string, mainId: string) {
  return fetchWrapper.post(`${baseUrl}/set-onboardingdate`, { id, mainId });
}

/// Function For getting The search user when logged user is admin----------------------------/
async function getSearchUsersOfAdmin(searchedUser: string) {
  return fetchWrapper.get(`${config.apiUrl}/api/searchUsers?searchedUser=${searchedUser}
  `);
}

/// Function For getting the search user when logged user is trainer--------------------------//
async function getSearchUsersOfTrainer(trainerCode: any, searchedUser: any) {
  return fetchWrapper.get(`${config.apiUrl}/api/searchUsers?searchedUser=${searchedUser}&trainerCode=${trainerCode}
  `);
}

/// Function For getting The user details----------------------------/
async function getTotalData(id: any, role: string) {
  return fetchWrapper.get(`${baseUrl}/get-user-totaldata?userId=${id}&role=${role}
  `);
}
