import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { discountCodeService } from "../services/discountcode.service";

const DiscountCode = () => {
  const [codes, setCodes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [codeUpdateLoading, setCodeUpdateLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  async function fetchCodes() {
    setLoading(true);
    try {
      const response = await discountCodeService.fetchDiscountCode();
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes(response.data);
        setLoading(false);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setLoading(false);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCodes();
  }, []);

  const handleAddCode = async (values: any) => {
    setCodeUpdateLoading(true);
    try {
      const response = await discountCodeService.addDiscountCode(values);

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes([
          ...codes,
          { _id: response.data._id, code: values.code, tag: values.tag },
        ]);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      console.error("Error adding code:", error);
    } finally {
      setCodeUpdateLoading(false);
    }
  };

  const handleDeleteCode = async (_id: number) => {
    setDeleteLoading(true);
    try {
      const response = await discountCodeService.deleteDiscountCode(_id);

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes(codes.filter((code) => code._id !== _id));
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      console.error("Error deleting code:", error);
    }
    setDeleteLoading(false);
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: "40px", padding: "0" }}>
      {loading ? (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Paper
          elevation={3}
          style={{
            padding: "24px",
            borderRadius: "12px",
            backgroundColor: "#f5f5f5",
            boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper style={{ padding: "16px" }}>
                <Typography variant="h6" gutterBottom>
                  Add New Code
                </Typography>
                <Formik
                  initialValues={{ code: "", tag: "" }}
                  onSubmit={(values, { resetForm }) => {
                    handleAddCode(values);
                    resetForm();
                  }}
                >
                  {({ handleChange, handleBlur, values }) => (
                    <Form>
                      <Field
                        as={TextField}
                        name="code"
                        label="Code"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      <Field
                        as={TextField}
                        name="tag"
                        label="Tag"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tag}
                      />
                      {codeUpdateLoading ? (
                        <Container>
                          <CircularProgress />
                        </Container>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "16px" }}
                        >
                          Add Code
                        </Button>
                      )}
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                style={{ padding: "16px", maxHeight: 600, overflowY: "scroll" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Discount Codes
                  </Typography>
                  {deleteLoading ? (
                    <Container>
                      <CircularProgress />
                    </Container>
                  ) : null}
                </Box>
                {codes.map((code) => (
                  <Box
                    key={code._id}
                    sx={{
                      marginBottom: "16px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">
                        Code: {code.code}
                        {" ,"} Tag: {code.tag}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteCode(code._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </Container>
  );
};

export default DiscountCode;
