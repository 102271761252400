import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { additionalService } from "../../services/additional.service";

interface MultipleChoiceFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  initialData?: {
    input_fields: {
      title: string;
      body?: string | null;
      imageUrl?: string | null;
      selectionType: "single" | "multiple";
      options: { label: string; imageUrl?: string | null }[];
    };
  };
}

const MultipleChoiceForm: React.FC<MultipleChoiceFormProps> = ({
  onClose,
  onSave,
  initialData,
}) => {
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [title, setTitle] = useState(initialData?.input_fields?.title || "");
  const [body, setBody] = useState(initialData?.input_fields?.body || null);
  const [type, setType] = useState(
    initialData?.input_fields?.selectionType || "single"
  ); // Default to "multiple"
  const [options, setOptions] = useState<
    { label: string; image: File | null }[]
  >(
    initialData?.input_fields?.options.map((opt) => ({
      label: opt.label,
      image: null,
    })) || [{ label: "", image: null }]
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setMainImageFile(
        new File([], initialData.input_fields.imageUrl.split("/").pop() || "")
      );
    }
  }, [initialData]);

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index].label = value;
    setOptions(newOptions);
  };

  const handleImageChangeForOption = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    const newOptions = [...options];
    newOptions[index].image = file;
    setOptions(newOptions);
  };

  const handleRemoveImageForOption = (index: number) => {
    const newOptions = [...options];
    newOptions[index].image = null;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { label: "", image: null }]);
  };

  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setMainImageFile(file);
  };

  const handleRemoveMainImage = () => {
    setMainImageFile(null);
  };

  const handleSubmit = async () => {
    if (!title || options.length === 0) {
      alert("Please fill out all fields.");
      return;
    }

    setLoading(true);
    try {
      // Upload the main image if available
      let mainImageUrl = initialData?.input_fields?.imageUrl || null;
      if (
        mainImageFile &&
        mainImageFile.name !==
          initialData?.input_fields?.imageUrl?.split("/").pop()
      ) {
        mainImageUrl = await additionalService.uploadImageOnAzure(
          mainImageFile
        );
        console.log("Main image URL:", mainImageUrl);
      }

      // Upload all images for options concurrently
      const uploadedOptions = await Promise.all(
        options.map(async (option, index) => {
          if (option.image) {
            const imageUrl = await additionalService.uploadImageOnAzure(
              option.image
            );
            return { label: option.label, imageUrl };
          } else {
            return {
              label: option.label,
              imageUrl:
                initialData?.input_fields?.options[index]?.imageUrl || null,
            };
          }
        })
      );

      const formData = {
        type: "multiple_choice",
        input_fields: {
          title,
          body,
          selectionType: type,
          options: uploadedOptions,
          imageUrl: mainImageUrl, // Include the main image URL if it was uploaded
        },
      };

      console.log("this is final formdata");
      console.log(formData);

      onSave(formData);
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle the error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        maxHeight: "60vh",
        overflowY: "auto",
        mx: "auto",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Multiple Choice Form
      </Typography>
      <Box sx={{ mb: 2, textAlign: "center" }}>
        <Button variant="outlined" component="label" color="primary">
          Upload Main Image (Optional)
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleMainImageChange}
          />
        </Button>
        {mainImageFile && (
          <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
            <Typography variant="body2">{mainImageFile.name}</Typography>
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={handleRemoveMainImage}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Type</InputLabel>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value as "single" | "multiple")}
          label="Type"
        >
          <MenuItem value="multiple">Multiple</MenuItem>
          <MenuItem value="single">Single</MenuItem>
        </Select>
      </FormControl>
      {options.map((option, index) => (
        <Box key={index} sx={{ mb: 2, width: "100%" }}>
          <TextField
            label={`Option ${index + 1}`}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            value={option.label}
            onChange={(e) => handleOptionChange(index, e.target.value)}
          />
          <Button
            variant="outlined"
            component="label"
            color="primary"
            fullWidth
          >
            Upload Image for Option {index + 1} (Optional)
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => handleImageChangeForOption(index, e)}
            />
          </Button>
          {option.image ? (
            <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
              <Typography variant="body2">{option.image.name}</Typography>
              <IconButton
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleRemoveImageForOption(index)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : initialData?.input_fields?.options[index]?.imageUrl ? (
            <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
              <Typography variant="body2">
                {initialData.input_fields.options[index].imageUrl
                  ?.split("/")
                  .pop()}
              </Typography>
              <IconButton
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleRemoveImageForOption(index)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      ))}
      <Button variant="outlined" onClick={handleAddOption}>
        Add Option
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {initialData ? "Update" : "Add"}{" "}
          {/* Change button text based on mode */}
        </Button>
      )}
    </Box>
  );
};

export default MultipleChoiceForm;
