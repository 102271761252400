import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Modal,
  TextField,
  MenuItem,
  Divider,
  IconButton,
  Drawer,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  CircularProgress,
  Container,
  CardContent,
  Card,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TopAppBar from "../modules/TopAppBar";
import { Close, PhotoCamera } from "@mui/icons-material";
import { configureService } from "../services/configure.service";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ResponseModal from "../Components/AppMonitor/ResponseModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GraphComponent from "../modules/LlmGraphData";
import { additionalService } from "../services/additional.service";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

function extractSeconds(timeString: string) {
  // Regular expression to match minutes and seconds
  const regex = /(\d+)m\s*(\d+\.\d+)s/;
  const match = timeString.match(regex);

  if (match) {
    const minutes = parseFloat(match[1]);
    const seconds = parseFloat(match[2]);
    // Convert minutes to seconds and add to the seconds part
    const totalSeconds = minutes * 60 + seconds;
    return totalSeconds;
  } else {
    throw new Error("Invalid time format");
  }
}

const contentStyle = {
  flex: "1 1 auto",
  overflowY: "auto",
  mb: 2,
};
const intitalCurrentConfigurationData = {
  provider: "",
  region: "",
  deploymentName: "",
  modelName: "",
};
const ApiMonitor = () => {
  const [open, setOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [llmModalFormOpen, setLlmModalFormOpen] = useState(false);
  const [configureJsonData, setConfigureJsonData] = useState<any>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseResponseModal = () => setResponseModalOpen(false);
  const handleLlmModalClose = () => {
    setLlmModalFormOpen(false);
    setCurrentConfiurationData(intitalCurrentConfigurationData);
  };
  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const [loading, setLoading] = useState(false);
  const [llmApiData, setLlmApiData] = useState<any[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [llmApiConfigureData, setLlmApiConfigureData] = useState<any[]>([]);
  const [currentConfigurationData, setCurrentConfiurationData] = useState<any>(
    intitalCurrentConfigurationData
  );
  const [totalLlmDataLength, setTotalLlmDataLength] = useState();
  const [stream, setStream] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const [currentResponseData, setCurrentResponseData] = useState<any[]>();
  const [saveConfigureDataLoading, setConfigureDataSaveLoading] =
    useState(false);
  const [graphModalOpen, setGraphModalOpen] = useState(false);

  const [iconLoading, setIconLoading] = useState(false);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50; // Number of rows per page
  const [openAiToggleValue, setOpenAiToggleValue] = useState("Gpt4");
  const [openAiButtonLoading, setOpenAiButtonLoading] = useState(false);
  const handleToggleChange = async (event: any, newToggleValue: any) => {
    if (newToggleValue !== null) {
      setOpenAiButtonLoading(true);
      try {
        const updatedResponse = await additionalService.updateOpenAiCredentials(
          newToggleValue
        );
        if (updatedResponse.success) {
          setSnackbarMessage(updatedResponse.message);
          setSnackbarOpen(true);
          setOpenAiToggleValue(updatedResponse.updatedData);
        } else {
          setSnackbarMessage(updatedResponse.message);
          setSnackbarOpen(true);
        }
      } catch (error: any) {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      }
      setOpenAiToggleValue(newToggleValue);
    }
    setOpenAiButtonLoading(false);
  };

  //// Fucntion for getting the openai credvalue--------------------------------.

  const graphModalClose = () => {
    setGraphModalOpen(false);
  };
  useEffect(() => {
    if (configureJsonData && configureJsonData.image) {
      setUploadedImage(configureJsonData.image);
    }
  }, [configureJsonData]);
  const handleImageUpload = (
    event: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedImage(URL.createObjectURL(file));
      setFieldValue("image", file);
    }
  };

  const handleImageDelete = (
    setFieldValue: (field: string, value: any) => void
  ) => {
    setUploadedImage(null);
    setFieldValue("image", null);
  };

  async function handleSubmit(values: any) {
    setLoading(true);

    // // Filter out keys with empty string or null values
    // const filteredValues: { [key: string]: any } = Object.fromEntries(
    //   Object.entries(values).filter(
    //     ([_, value]) => value !== "" && value !== null
    //   )
    // );

    setLoading(false);
    if (values) {
      try {
        //Replace newline characters in the prompt with \\n
        // if (values && values.prompt) {
        //   values.prompt = values.prompt.replace(/\n/g, "\\n");
        // }
        // console.log("this is filtertedvalues");
        // console.log(values);

        // Data is there so we will make the update call
        const response = await configureService.updateConfigurationJson(values);
        let jsonData = await response.json();

        if (jsonData.success) {
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarMessage(`${jsonData.message}`);
          setConfigureJsonData(jsonData.updatedData);
        } else {
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarMessage(`${jsonData.message}`);
        }
      } catch (error) {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Some error has happened");
      }
    } else {
      setSnackbarOpen(true);
      setSnackbarMessage("Write down the values");
    }
  }

  function handleCloseSnackbar() {
    setSnackbarOpen(false);
  }

  /// Function for getting the configure data -----------------------------------------------------/
  async function getData(currentPage: number) {
    setLoading(true);
    setOpenAiButtonLoading(true);
    try {
      const response = await configureService.getConfigureData(currentPage);
      const openAiResponse = await additionalService.getOpenAiCredentials();

      if (response.success) {
        setLoading(false);
        setOpenAiButtonLoading(false);
        setOpenAiToggleValue(openAiResponse.data);
        setSnackbarOpen(true);
        setLlmApiData(response.testLogData);
        setSnackbarMessage("Data has been fetched");
        setTotalLlmDataLength(response.testLogLength);
        if (response.data.length > 0) {
          setLlmApiConfigureData(response.data);
        } else {
          setLlmApiConfigureData([]);
        }
      } else {
        setOpenAiButtonLoading(false);
        setLlmApiConfigureData([]);
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(`${response.data}`);
      }
    } catch (error) {
      setLoading(false);
      setOpenAiButtonLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to fetch data");
    }
  }

  useEffect(() => {
    getData(currentPage);
  }, []);

  //// function for running the llp api's manually ------------------------------------------------------/
  const runLlmApiManually = async () => {
    setRunLoading(true);
    try {
      let promises = [];
      for (let i = 0; i < llmApiConfigureData.length; i++) {
        let configureData = { ...llmApiConfigureData[i], ...configureJsonData };
        let data = {
          configureData: configureData,
          stream: stream,
        };

        // Collect promises without awaiting
        promises.push(configureService.runLlmApi(data));
      }

      // Await all promises in parallel
      const responses = await Promise.all(promises);

      // Process responses here if needed
      // For example, you could update state with responses
      console.log(responses);

      setStream(false);
      setRunLoading(false);
      getData(currentPage);
    } catch (error) {
      setRunLoading(false);
      console.error("Error running LLM API:", error);
    }
  };
  async function fetchConfigureJsonData() {
    try {
      const response = await configureService.getConfigureJsonData();
      if (response) {
        setConfigureJsonData(response);
      } else {
        let demoData = {
          image: null,
          hint: "",
          imageprompt: "",
          textprompt: "",
        };
        setConfigureJsonData(demoData);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchConfigureJsonData();
  }, []);

  /// Async function for adding the configure data -----------------------------------------------------/
  async function addingConfigureData(values: any) {
    setConfigureDataSaveLoading(true);

    try {
      let data = { ...values };
      if (currentConfigurationData && currentConfigurationData._id) {
        data._id = currentConfigurationData._id;
      }
      const addingResponse = await configureService.addConfigureData(data);

      if (addingResponse.success) {
        if (addingResponse.operationType === "update") {
          /// we are updating the existing data
          setLlmApiConfigureData((prevData) =>
            prevData.filter((item) => item._id !== data._id)
          ); // Remove the element with the same id
          setLlmApiConfigureData((prevData) => [...prevData, data]);
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been updated");
          setConfigureDataSaveLoading(false);
          setCurrentConfiurationData(intitalCurrentConfigurationData);
          setLlmModalFormOpen(false);
        } else if (addingResponse.operationType === "add") {
          // adding new configuraiton data
          setSnackbarMessage(addingResponse.message);
          setConfigureDataSaveLoading(false);
          setSnackbarOpen(true);
          setLlmApiConfigureData([...llmApiConfigureData, values]);
          setLlmModalFormOpen(false);
        }
      } else {
        setSnackbarMessage(addingResponse.message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setConfigureDataSaveLoading(false);
    }
  }
  async function deleteConfigurationData(id: string) {
    setIconLoading(true);

    try {
      const response = await configureService.deleteData(id);
      if (response.success) {
        setLlmApiConfigureData((prevData) =>
          prevData.filter((item) => item._id !== id)
        ); // Remove the element with the same id
        setSnackbarOpen(true);
        setSnackbarMessage("Data has been deleted");
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
      setIconLoading(false);
    } catch (error) {
      setIconLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Some error has happened");
    }
  }

  /// Fucntion for handeling the response modal opening -----------------------------------------------------/
  function handleResponseModal(responseData: any) {
    setCurrentResponseData(responseData);
    setResponseModalOpen(true);
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // Fetch new data based on the new page
    getData(page);
  };

  const renderPaginationButtons = () => {
    if (totalLlmDataLength) {
      const totalPages = Math.ceil(totalLlmDataLength / pageSize);

      return (
        <div>
          <IconButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
      );
    } else {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }
  };

  return (
    <>
      <TopAppBar />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "95%",
            height: "90vh",
            backgroundColor: "#f5f5f5",
            padding: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "90%",
              height: "80%",
              padding: 2,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  API Monitor
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  {renderPaginationButtons()}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setGraphModalOpen(true)}
                >
                  Graph
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="configure"
                    onClick={handleDrawerOpen}
                    size="small"
                    sx={{
                      borderRadius: "50%",
                      width: 50,
                      height: 50,
                      marginBottom: 2,
                      bgcolor: "#3f51b5",
                      "&:hover": {
                        bgcolor: "#2c387e",
                      },
                    }}
                  >
                    <SettingsIcon sx={{ color: "white" }} />
                  </IconButton>
                  {openAiButtonLoading ? (
                    <Container>
                      <CircularProgress />
                    </Container>
                  ) : (
                    <ToggleButtonGroup
                      value={openAiToggleValue}
                      exclusive
                      onChange={handleToggleChange}
                      sx={{ marginLeft: 2 }}
                    >
                      <ToggleButton value="Gpt4">Gpt4</ToggleButton>
                      <ToggleButton value="Gpt4o">Gpt4o</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                  >
                    LLM API
                  </Button>
                  {runLoading ? (
                    <Container>
                      <CircularProgress />
                    </Container>
                  ) : (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={runLlmApiManually}
                        sx={{ marginLeft: 2 }}
                      >
                        Run
                      </Button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={stream}
                            onChange={(e) => setStream(e.target.checked)}
                            name="stream"
                            color="primary"
                            sx={{ marginLeft: 1 }}
                          />
                        }
                        label="Stream"
                      />
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
            {loading ? (
              <Container
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <TableContainer
                sx={{
                  height: "55vh",
                  overflowY: "auto",
                  marginBottom: 5,
                  marginTop: 1,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Provider
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Instance Name
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Deployment Name
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Model
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Date Time
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Text
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Image
                      </TableCell>
                      <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                        Text+Image
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Latency
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Response Time
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Tokens/s
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Latency
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Response Time
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Tokens/s
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Latency
                      </TableCell>
                      <TableCell>Response Time</TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        Tokens/s
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ overflowY: "scroll" }}>
                    {llmApiData &&
                      llmApiData.map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.provider}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.region}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.deploymentName}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.modelName}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {format(
                              new Date(row.createdAt),
                              "MM/dd/yyyy hh:mm:ss a"
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.textResponse ? row.textResponse.latency : ""}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleResponseModal(row.textResponse?.response)
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {row.textResponse
                              ? row.textResponse.responseTime
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.textResponse
                              ? row.textResponse.usage
                                ? row.textResponse.usage.completion_tokens
                                  ? (
                                      row.textResponse.usage.completion_tokens /
                                      extractSeconds(
                                        row.textResponse.responseTime
                                      )
                                    ).toFixed(2)
                                  : ""
                                : ""
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.imageResponse ? row.imageResponse.latency : ""}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleResponseModal(row.imageResponse?.response)
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {row.imageResponse
                              ? row.imageResponse.responseTime
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.imageResponse
                              ? row.imageResponse.usage
                                ? row.imageResponse.usage.completion_tokens
                                  ? (
                                      row.imageResponse.usage
                                        .completion_tokens /
                                      extractSeconds(
                                        row.imageResponse.responseTime
                                      )
                                    ).toFixed(2)
                                  : ""
                                : ""
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.textAndImageResponse
                              ? row.textAndImageResponse.latency
                              : ""}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleResponseModal(
                                row.textAndImageResponse?.response
                              )
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {row.textAndImageResponse
                              ? row.textAndImageResponse.responseTime
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.textAndImageResponse
                              ? row.textAndImageResponse.usage
                                ? row.textAndImageResponse.usage
                                    .completion_tokens
                                  ? (
                                      row.textAndImageResponse.usage
                                        .completion_tokens /
                                      extractSeconds(
                                        row.textAndImageResponse.responseTime
                                      )
                                    ).toFixed(2)
                                  : ""
                                : ""
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Box>
      </Box>

      <Modal
        open={llmModalFormOpen}
        aria-labelledby="add-llm-api-modal"
        aria-describedby="add-llm-api-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: 2,
          }}
        >
          <Paper elevation={3} sx={{ width: "400px", padding: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Add LLM API
              </Typography>
              <IconButton onClick={handleLlmModalClose}>
                <Close />
              </IconButton>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Formik
              initialValues={currentConfigurationData}
              onSubmit={(values, { setSubmitting }) => {
                addingConfigureData(values);
                setSubmitting(false);
                handleClose();
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <Field
                    as={TextField}
                    name="provider"
                    label="Provider"
                    select
                    fullWidth
                    margin="normal"
                    helperText={
                      <Box sx={{ color: "red" }}>
                        <ErrorMessage name="provider" />
                      </Box>
                    }
                    error={touched.provider && !!errors.provider}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "red",
                        },
                    }}
                  >
                    <MenuItem value="azure">Azure</MenuItem>
                  </Field>
                  <Field
                    as={TextField}
                    name="region"
                    label="Instance Name"
                    fullWidth
                    margin="normal"
                    helperText={
                      <Box sx={{ color: "red" }}>
                        <ErrorMessage name="region" />
                      </Box>
                    }
                    error={touched.region && !!errors.region}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "red",
                        },
                    }}
                  />
                  <Field
                    as={TextField}
                    name="deploymentName"
                    label="Deployment Name"
                    fullWidth
                    margin="normal"
                    helperText={
                      <Box sx={{ color: "red" }}>
                        <ErrorMessage name="deploymentName" />
                      </Box>
                    }
                    error={touched.deploymentName && !!errors.deploymentName}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "red",
                        },
                    }}
                  />
                  <Field
                    as={TextField}
                    name="modelName"
                    label="Model"
                    fullWidth
                    margin="normal"
                    helperText={<ErrorMessage name="modelName" />}
                    error={touched.modelName && !!errors.modelName}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 2,
                    }}
                  >
                    {saveConfigureDataLoading ? (
                      <Container>
                        <CircularProgress />
                      </Container>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Modal>
      {/* //// Modal code for the llm list showing --------------------------------------------------------------/ */}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={contentStyle}>
            {llmApiConfigureData &&
              llmApiConfigureData.length > 0 &&
              llmApiConfigureData.map((item, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6">
                      {" "}
                      Provider: {item.provider}
                    </Typography>
                    <Typography variant="body2">
                      Instance Name: {item.region}
                    </Typography>
                    <Typography variant="body2">
                      Deployment Name: {item.deploymentName}
                    </Typography>
                    <Typography variant="body2">
                      Model Name: {item.modelName}
                    </Typography>

                    <IconButton
                      onClick={() => {
                        deleteConfigurationData(item._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton>
                      <EditIcon
                        onClick={() => {
                          setCurrentConfiurationData(item);
                          setLlmModalFormOpen(true);
                        }}
                      />
                    </IconButton>
                  </CardContent>
                </Card>
              ))}
            {iconLoading ? (
              <Container
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            ) : null}
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setOpen(false);
              setLlmModalFormOpen(true);
            }}
          >
            Add
          </Button>
        </Box>
      </Modal>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "400px" }}
      >
        <Box sx={{ width: "400px", padding: 3 }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Settings
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          <Formik
            initialValues={configureJsonData}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              console.log("this are the values of settings");
              console.log(values);

              handleSubmit(values);
              setSubmitting(false);
              handleDrawerClose();
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                {!uploadedImage && (
                  <FormControl fullWidth margin="normal">
                    <OutlinedInput
                      id="image-upload"
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      onChange={(event) =>
                        handleImageUpload(event, setFieldValue)
                      }
                      endAdornment={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      }
                      label="Image"
                    />
                    <ErrorMessage name="image" component="div" />
                  </FormControl>
                )}

                {uploadedImage && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "16px 0",
                    }}
                  >
                    <img
                      src={uploadedImage}
                      alt="Uploaded"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <IconButton
                      color="secondary"
                      onClick={() => handleImageDelete(setFieldValue)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}

                <Field
                  as={TextField}
                  name="hint"
                  label="Hint"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  helperText={<ErrorMessage name="hint" />}
                />
                <Field
                  as={TextField}
                  name="imageprompt"
                  label="Image Prompt"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={10}
                  helperText={<ErrorMessage name="imageprompt" />}
                />
                <Field
                  as={TextField}
                  name="textprompt"
                  label=" Text Prompt"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={10}
                  helperText={<ErrorMessage name="textprompt" />}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 2,
                  }}
                >
                  {loading ? (
                    <Container>
                      <CircularProgress />
                    </Container>
                  ) : (
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
      <ResponseModal
        responseModalOpen={responseModalOpen}
        handleCloseResponseModal={handleCloseResponseModal}
        nutritionData={currentResponseData}
      />
      <GraphComponent
        graphModalClose={graphModalClose}
        graphModalOpen={graphModalOpen}
      />
    </>
  );
};

export default ApiMonitor;
