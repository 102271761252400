/** @format */
import { BlobServiceClient } from "@azure/storage-blob";
import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
import imageCompression from "browser-image-compression";
const baseUrl = `${config.apiUrl}/api`;
/// function for calling the api of sending notification-----------------------/
export const additionalService = {
  sendNotification,
  sendEmail,
  getOpenAiCredentials,
  updateOpenAiCredentials,
  uploadImageOnAzure,
};

function sendNotification(body: any) {
  return fetchWrapper.post(
    `${baseUrl}/sendNotification
    `,
    body
  );
}
function sendEmail(body: any) {
  return fetchWrapper.post(
    `${baseUrl}/sendEmail
    `,
    body
  );
}

//// Api monitor service funcitons -----------------------------------------------------/
function getOpenAiCredentials() {
  return fetchWrapper.get(
    `${baseUrl}/get-currentopenai-credentials
    `
  );
}
async function getAccountDetails(): Promise<{
  sasToken: string;
  storageAccount: string;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-account-details
    `
  );
}

function updateOpenAiCredentials(type: string) {
  return fetchWrapper.put(
    `${baseUrl}/update-openai-credentials
    `,
    { type: type }
  );
}

/// function for uploading image to azure--------------------------------------------/
async function uploadImageOnAzure(file: File): Promise<any> {
  try {
    // Set the target size in KB (512 KB = 0.512 MB)
    const targetSizeKB = 512;

    // Compress the image file before uploading
    const compressedFile = await imageCompression(file, {
      maxSizeMB: targetSizeKB / 1024, // Convert KB to MB
      maxWidthOrHeight: 1920, // Set maximum width or height for the image
      useWebWorker: true, // Use web workers for faster processing
    });

    // Ensure that the width is the same as the original (no cropping)
    const imageBitmap = await createImageBitmap(file);
    const originalWidth = imageBitmap.width;

    if (imageBitmap.width !== originalWidth) {
      throw new Error("Image width has been altered during compression.");
    }

    const accountDetailsResponse = await getAccountDetails();

    // Initialize the BlobServiceClient with a connection string or SAS token
    const blobServiceClient = new BlobServiceClient(
      `https://${accountDetailsResponse.storageAccount}.blob.core.windows.net?${accountDetailsResponse.sasToken}`
    );

    // Get a reference to the container
    const containerClient =
      blobServiceClient.getContainerClient("reshape-public");

    // Create a unique blob name for the file, including the "campaign" folder
    const blobName = `campaign/${new Date().getTime()}-${compressedFile.name}`;

    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Upload the compressed file
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      compressedFile,
      {
        blobHTTPHeaders: {
          blobContentType: compressedFile.type,
        },
      }
    );

    // Extract the base URL without the SAS token or query parameters
    const blobUrlWithoutSasToken = blockBlobClient.url.split("?")[0];

    console.log(blobUrlWithoutSasToken);

    // Return the blob URL without the SAS token
    return blobUrlWithoutSasToken;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}
