import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { additionalService } from "../../services/additional.service";

interface StarRatingFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  initialData?: {
    input_fields: {
      title: string;
      body?: string;
      imageUrl?: string | null;
      feedbackText?: string;
      feedbackBoxPlaceholder?: string;
      ratingType: "5star" | "10star";
      feedbackType: "withFeedback" | "withoutFeedback";
    };
  };
}

const StarRatingForm: React.FC<StarRatingFormProps> = ({
  onClose,
  onSave,
  initialData,
}) => {
  console.log("initial data");

  console.log(initialData);

  const [title, setTitle] = useState(initialData?.input_fields?.title || "");
  const [body, setBody] = useState(initialData?.input_fields?.body || "");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [feedbackText, setFeedbackText] = useState(
    initialData?.input_fields?.feedbackText || ""
  );
  const [feedbackBoxPlaceholder, setFeedbackBoxPlaceholder] = useState(
    initialData?.input_fields?.feedbackBoxPlaceholder || ""
  );
  const [type, setType] = useState(
    initialData?.input_fields?.feedbackType || "withoutFeedback"
  );
  const [ratingType, setRatingType] = useState(
    initialData?.input_fields?.ratingType || "5star"
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setImageName(initialData.input_fields.imageUrl.split("/").pop() || "");
    }
  }, [initialData]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImageFile(file);
    setImageName(file ? file.name : null);
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImageName(null);
  };

  const handleSubmit = async () => {
    if (
      !title ||
      (type === "withFeedback" && (!feedbackText || !feedbackBoxPlaceholder))
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    setLoading(true);
    let imageUrl = initialData?.input_fields?.imageUrl || null;

    try {
      if (imageFile) {
        // If there is a new image, upload it
        imageUrl = await additionalService.uploadImageOnAzure(imageFile);
        console.log("This is the image URL:", imageUrl);
      }

      // Prepare the data to be saved
      const formData: any = {
        type: "rating",
        input_fields: {
          title,
          body,
          ratingType,
          feedbackText: type === "withFeedback" ? feedbackText : undefined,
          feedbackBoxPlaceholder:
            type === "withFeedback" ? feedbackBoxPlaceholder : undefined,
          imageUrl, // Include the image URL if it was uploaded
          feedbackType: type,
        },
      };

      console.log("This is the rating form data:");
      console.log(formData);

      onSave(formData);
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle the error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        maxHeight: "60vh",
        overflowY: "auto",
        mx: "auto",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Star Rating Form
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Box sx={{ mb: 2, textAlign: "center" }}>
        <Button variant="outlined" component="label" color="primary">
          Upload Image (Optional)
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleImageChange}
          />
        </Button>
        {imageName && (
          <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
            <Typography variant="body2">{imageName}</Typography>
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={handleRemoveImage}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          labelId="type-label"
          value={type}
          onChange={(e) =>
            setType(e.target.value as "withFeedback" | "withoutFeedback")
          }
          label="Type"
        >
          <MenuItem value="withoutFeedback">Without Feedback</MenuItem>
          <MenuItem value="withFeedback">With Feedback</MenuItem>
        </Select>
      </FormControl>
      {type === "withFeedback" && (
        <>
          <TextField
            label="Feedback Text"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <TextField
            label="Feedback Box Placeholder"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={feedbackBoxPlaceholder}
            onChange={(e) => setFeedbackBoxPlaceholder(e.target.value)}
          />
        </>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="ratingType-label">Rating Type</InputLabel>
        <Select
          labelId="ratingType-label"
          value={ratingType}
          onChange={(e) => setRatingType(e.target.value as "5star" | "10star")}
          label="Rating Type"
        >
          <MenuItem value="5star">5 Star</MenuItem>
          <MenuItem value="10star">10 Star</MenuItem>
        </Select>
      </FormControl>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {initialData ? "Update" : "Add"}{" "}
          {/* Change button text based on mode */}
        </Button>
      )}
    </Box>
  );
};

export default StarRatingForm;
