import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
import { accountService } from "./account.service";

const baseUrl = `${config.apiUrl}/api`;
export const discountCodeService = {
  addDiscountCode,
  fetchDiscountCode,
  deleteDiscountCode,
};

//// function for adding the discount code ------------------------------------------/
async function addDiscountCode(value: any) {
  return fetchWrapper.post(`${baseUrl}/add-discount-code`, {
    code: value.code,
    tag: value.tag,
  });
}

//// Function for fetching the discount code ---------------------------------------------/
async function fetchDiscountCode() {
  return fetchWrapper.get(`${baseUrl}/get-discount-codes`);
}

//// Function for \deltting the code -----------------------------/
async function deleteDiscountCode(codeId: any) {
  return fetchWrapper.delete(
    `${baseUrl}/delete-discount-code?codeId=${codeId}`
  );
}
