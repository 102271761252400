/** @format */

import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  MultiPromptInterface,
  PromptsInterface,
  SinglePromptInterface,
} from "../../../interface/RecipeInterface";
import { recipeService } from "../../../services/recipe.service";

function MultiPromptSection({
  multiRecipe,
  setMultiRecipe,
  multiRecipeLoading,
  setMultiRecipeLoading,
  prompts,
  setPrompts,
}: MultiPromptInterface) {
  const [mode, setMode] = useState("AI");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [useDalle, setUseDalle] = useState(true);
  const [multiChatGptPrompt, setMultiChatGptPrompt] = useState("");
  const [multiDalleImagePrompt, setMultiDalleImagePrompt] = useState("");
  const [initialValues, setInitialValues] = useState({
    dalleInput: "",
    chatGptInput: "",
    recipeInput: "",
  });
  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSubmit = async (values: any) => {
    setMultiRecipeLoading(true);
    try {
      // Retrieve the entire manually typed input including the output structure from values.chatGpt4
      let manualPrompt = values.chatGptInput.replace(/\n/g, "\\n");
      setPrompts((prev: PromptsInterface) => ({
        ...prev,
        gpt: values.chatGptInput,
        dalle: values.dalleInput,
        recipeDetails: values.recipeInput,
      }));
      // Add the logging information at the beginning of the prompt
      let fullPrompt = `- Input User query is provided to you as "recipe details"\n${manualPrompt}`;

      // Create an array of recipe input strings
      let recipeInputs = values.recipeInput.split(",");

      // Make parallel calls for recipes and images
      let recipePromises = recipeInputs.map((recipe: string) => {
        return recipeService.getSingleRecipesFromAi(fullPrompt, recipe.trim());
      });

      let dalleImagePromises = recipeInputs.map((recipe: string) => {
        let dalleImagePrompt = `${
          values.dalleInput
        } Recipe Details is ${recipe.trim()}`;
        return recipeService.getImageForDalle([dalleImagePrompt], "single");
      });

      // Wait for all promises to resolve
      Promise.all([...recipePromises, ...dalleImagePromises])
        .then((responses) => {
          let recipeResponses = responses.slice(0, recipeInputs.length);
          let dalleImageResponses = responses.slice(recipeInputs.length);

          // Extract and assign images to recipes
          let recipeDetails = recipeResponses.map((response, index) => {
            return {
              ...response.recipeDetails,
              image: dalleImageResponses[index].recipeImage[0],
            };
          });

          // Store in local storage
          localStorage.setItem("multiChatGptPrompt", fullPrompt);
          localStorage.setItem("multiDalleImagePrompt", values.dalleInput);

          // Extract message and success status from the first recipe response
          let { message, success } = recipeResponses[0];

          if (success) {
            setMultiRecipeLoading(false);
            setSnackbarOpen(true);
            setMultiRecipe(recipeDetails);
            setSnackbarMessage(message);
          } else {
            setMultiRecipeLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage(message);
            setMultiRecipe(null);
          }
        })
        .catch((error) => {
          console.log(error);
          setMultiRecipeLoading(false);
        });
    } catch (error) {
      console.log(error);
      setMultiRecipeLoading(false);
    }
  };

  useEffect(() => {
    // Fetch values from local storage
    const multiChatGptPrompt = localStorage.getItem("multiChatGptPrompt");
    const multiDalleImagePrompt = localStorage.getItem("multiDalleImagePrompt");

    // Update states if values are found in local storage
    if (multiChatGptPrompt) {
      setMultiChatGptPrompt(multiChatGptPrompt);
    }

    if (multiDalleImagePrompt) {
      setMultiDalleImagePrompt(multiDalleImagePrompt);
    }
    // Set initial values for Formik form once fullPrompt and dalleImagePrompt are updated
    setInitialValues({
      dalleInput: multiDalleImagePrompt || "",
      chatGptInput: multiChatGptPrompt || "",
      recipeInput: "", // Keep empty if not specified
    });
  }, []); // Empty dependency array ensures this runs once after the initial render

  useEffect(() => {
    console.log("this is multi recipe");
    console.log(multiRecipe);
  }, [multiRecipe]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",

        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "95%",
          backgroundColor: "#ffffff",

          borderRadius: 2,
          width: "95%",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(event, newMode) => newMode && setMode(newMode)}
            sx={{ mb: 2, width: "100%", justifyContent: "center" }}
          >
            <ToggleButton value="AI">AI</ToggleButton>
            <ToggleButton value="Manual">Manual</ToggleButton>
          </ToggleButtonGroup>
          {/* Switch component */}
          <FormControlLabel
            control={
              <Switch
                checked={useDalle}
                onChange={() => setUseDalle(!useDalle)}
                color="primary"
              />
            }
            label={useDalle ? "Dalle" : "Dalle"}
            labelPlacement="start"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: useDalle ? "primary.main" : "text.primary",
                fontWeight: "bold",
              },
            }}
          />
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {mode === "AI" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1, textAlign: "left" }}>
                      DALL-E Prompt
                    </Typography>

                    <Field
                      name="dalleInput"
                      as={TextField}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("dalleInput", value); // Update Formik state
                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          dalle: value,
                        }));
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      ChatGPT Prompt
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "95%",
                    }}
                  >
                    <Field
                      name="chatGptInput"
                      as={TextField}
                      multiline
                      rows={12}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("chatGptInput", value); // Update Formik state
                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          gpt: value,
                        }));
                      }}
                    />
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Recipe Details
                    </Typography>

                    <Field
                      name="recipeInput"
                      as={TextField}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2 }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue("recipeInput", value); // Update Formik state

                        setPrompts((prev: PromptsInterface) => ({
                          ...prev,
                          recipeDetails: value,
                        }));
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Manual Input
                  </Typography>
                  <Field
                    name="manualInput"
                    as={TextField}
                    multiline
                    rows={8}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </>
              )}
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                sx={{ mt: 2, width: "95%" }}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#1976d2", // Use primary color from the theme
              color: "white", // Set text color to white
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default MultiPromptSection;
