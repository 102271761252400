import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  Modal,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import { configureService } from "../services/configure.service";
import { format } from "date-fns";

type ResponseType = "imageResponse" | "textAndImageResponse" | "textResponse";

interface Log {
  _id: string;
  provider: string;
  region: string;
  deploymentName: string;
  modelName: string;
  imageResponse: { responseTime: string };
  textAndImageResponse: { responseTime: string };
  textResponse: { responseTime: string };
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const sampleData: Log[] = [
  // Sample data here
];

const fetchLlmGraphData = async (dateRange: {
  startDate: string;
  endDate: string;
}) => {
  try {
    // Simulate an API call
    return {
      message: "Data fetched successfully",
      success: true,
      data: sampleData.filter((log) => {
        const logDate = new Date(log.createdAt);
        return (
          logDate >= new Date(dateRange.startDate) &&
          logDate <= new Date(dateRange.endDate)
        );
      }),
    };
  } catch (error) {
    return {
      message: `An error has happened: ${(error as Error).message}`,
      success: false,
      data: [],
    };
  }
};

const ModalContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90%",
  overflowY: "auto",
  padding: theme.spacing(2),
}));

interface GraphComponentProps {
  graphModalOpen: boolean;
  graphModalClose: () => void;
}

const GraphComponent: React.FC<GraphComponentProps> = ({
  graphModalClose,
  graphModalOpen,
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("week").toISOString(),
    endDate: dayjs().endOf("week").toISOString(),
  });

  const [filter, setFilter] = useState<ResponseType>("imageResponse");
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState<"Week" | "Day" | "Month">("Week");
  const [chartData, setChartData] = useState<any[]>([]);
  useEffect(() => {
    console.log("this is the dateRange");
    console.log(dateRange);

    const getData = async () => {
      try {
        setLoading(true);

        const graphResponse = await configureService.fetchLlmGraphData(
          dateRange,
          filter,
          viewType === "Week" || viewType === "Month" ? "YYYY-MM-DD" : "HH:mm"
        );
        console.log("this is graph response");
        console.log(graphResponse);

        if (graphResponse.success) {
          setChartData(graphResponse.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("some error has happened");
      }
    };
    getData();
  }, [dateRange, filter, viewType]);

  const handlePrev = () => {
    if (viewType === "Week") {
      const currentStartDate = dayjs(dateRange.startDate);

      // Subtract 7 days from the current start date and ensure it's based on the local timezone
      const newStartDate = currentStartDate.subtract(7, "day").startOf("day");
      const newEndDate = newStartDate.add(6, "day").endOf("day");

      setDateRange({
        startDate: newStartDate.format("YYYY-MM-DDTHH:mm:ssZ"), // Store in local time
        endDate: newEndDate.format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    } else if (viewType === "Day") {
      setDateRange({
        startDate: dayjs(dateRange.startDate)
          .subtract(1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endDate: dayjs(dateRange.endDate)
          .subtract(1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    } else {
      setDateRange({
        startDate: dayjs(dateRange.startDate)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endDate: dayjs(dateRange.endDate)
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    }
  };
  const handleNext = () => {
    const currentDate = dayjs(); // Get the current date

    let newStartDate;
    let newEndDate;

    if (viewType === "Week") {
      newStartDate = dayjs(dateRange.startDate).add(1, "week");
      newEndDate = dayjs(dateRange.endDate).add(1, "week");
    } else if (viewType === "Day") {
      newStartDate = dayjs(dateRange.startDate).add(1, "day");
      newEndDate = dayjs(dateRange.endDate).add(1, "day");
    } else {
      newStartDate = dayjs(dateRange.startDate).add(1, "month");
      newEndDate = dayjs(dateRange.endDate).add(1, "month");
    }

    // Only update if the new end date is not in the future
    if (
      newEndDate.isBefore(currentDate) ||
      newEndDate.isSame(currentDate, "day")
    ) {
      setDateRange({
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString(),
      });
    }
  };

  const handleViewTypeChange = (view: "Week" | "Day" | "Month") => {
    setViewType(view);
    if (view === "Week") {
      setDateRange({
        startDate: dayjs().startOf("week").toISOString(),
        endDate: dayjs().endOf("week").toISOString(),
      });
    } else if (view === "Day") {
      setDateRange({
        startDate: dayjs().startOf("day").toISOString(),
        endDate: dayjs().endOf("day").toISOString(),
      });
    } else {
      setDateRange({
        startDate: dayjs().startOf("month").toISOString(),
        endDate: dayjs().endOf("month").toISOString(),
      });
    }
  };

  const generateDateRange = (
    startDate: string,
    endDate: string,
    interval: "day" | "hour" = "day"
  ) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const dates = [];
    let current = start;

    while (current <= end) {
      dates.push(current.format(interval === "day" ? "YYYY-MM-DD" : "HH:mm"));
      current = current.add(1, interval);
    }

    return dates;
  };

  const weekDays = generateDateRange(
    dateRange.startDate,
    dateRange.endDate,
    viewType === "Day" ? "hour" : "day"
  );

  const fillMissingDates = (data: any[], dateRange: string[]) => {
    const dateMap = new Map(data.map((d) => [d.date, d]));
    return dateRange.map((date) => {
      if (dateMap.has(date)) {
        return dateMap.get(date);
      } else {
        const emptyObject: Record<string, any> = { date };
        data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key !== "date") emptyObject[key] = null;
          });
        });
        return emptyObject;
      }
    });
  };

  const minYAxisValue = Math.min(
    ...chartData.flatMap((item) =>
      Object.values(item).map((d) => (typeof d === "number" ? d : Infinity))
    )
  );
  const maxYAxisValue = Math.max(
    ...chartData.flatMap((item) =>
      Object.values(item).map((d) => (typeof d === "number" ? d : -35))
    )
  );

  const yAxisBuffer = (maxYAxisValue - minYAxisValue) * 0.5; // 10% buffer

  const tickFormatter = (tick: string) => {
    if (viewType === "Week" || viewType === "Month") {
      return format(new Date(tick), "dd/MM");
    } else {
      return format(new Date(`1970-01-01T${tick}:00Z`), "HH:mm");
    }
  };

  const renderDayChart = (chartData: any[]) => {
    console.log("this is day chartdata");
    console.log(chartData);

    // Extract all keys from chartData except 'date'
    const keys = Array.from(
      new Set(
        chartData.flatMap((data) =>
          Object.keys(data).filter((key) => key !== "date")
        )
      )
    );

    // Ensure every hour from 00:00 to 23:00 is present in the data
    const fullDayHours = Array.from({ length: 24 }, (_, i) => {
      const date = `${i.toString().padStart(2, "0")}:00`;
      const dataPoint = chartData.find((item) => item.date === date) || {
        date,
      };
      keys.forEach((key) => {
        if (!dataPoint.hasOwnProperty(key)) {
          dataPoint[key] = null;
        }
      });
      return dataPoint;
    });

    const tickFormatter = (tick: string) => {
      return tick; // Directly use the tick value which is in HH:MM format
    };

    const minYAxisValue = Math.min(
      ...chartData.flatMap((d) =>
        Object.values(d).filter((v): v is number => typeof v === "number")
      )
    );

    return (
      <div style={{ overflowX: "auto" }}>
        <ResponsiveContainer width={2400} height={500}>
          <LineChart
            data={fullDayHours}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 2" />
            <XAxis dataKey="date" tickFormatter={tickFormatter} interval={0}>
              <Label value="date-time" offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis
              domain={[Math.min(0, minYAxisValue - 1), 40]}
              tickFormatter={(value) =>
                typeof value === "number" ? value.toFixed(1) : value
              }
            >
              <Label
                value="response-time"
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip
              formatter={(value) =>
                typeof value === "number" ? value.toFixed(1) : value
              }
            />
            <Legend />
            {keys.map((key, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={key}
                name={key}
                stroke={`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, 1)`}
                activeDot={{ r: 8 }}
                connectNulls
              />
            ))}
            <ReferenceLine y={0} stroke="#000" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };
  const renderChart = () => {
    console.log("this is chartdata");
    console.log(chartData);

    return (
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 2" />
          <XAxis
            dataKey="date"
            interval="preserveStartEnd"
            tickFormatter={tickFormatter}
            padding={{ left: 50 }}
            ticks={weekDays}
          >
            <Label value="date-time" offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis
            domain={[Math.min(0, minYAxisValue - 1), 40]}
            tickFormatter={(value) =>
              typeof value === "number" ? value.toFixed(1) : value
            }
          >
            <Label
              value="response-time"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip
            formatter={(value) =>
              typeof value === "number" ? value.toFixed(1) : value
            }
          />
          <Legend />
          {Object.keys(chartData[0] || {}).map((key, index) => {
            if (key !== "date") {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  name={key}
                  stroke={`rgba(${Math.floor(
                    Math.random() * 255
                  )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                    Math.random() * 255
                  )}, 1)`}
                  activeDot={{ r: 8 }}
                />
              );
            }
            return null;
          })}
          <ReferenceLine y={0} stroke="#000" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Modal open={graphModalOpen} onClose={graphModalClose}>
      <ModalContainer>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2" gutterBottom>
            LLM Graph Data
          </Typography>
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => handleViewTypeChange("Week")}
              variant={viewType === "Week" ? "contained" : "outlined"}
            >
              Week
            </Button>
            <Button
              onClick={() => handleViewTypeChange("Day")}
              variant={viewType === "Day" ? "contained" : "outlined"}
            >
              Day
            </Button>
            <Button
              onClick={() => handleViewTypeChange("Month")}
              variant={viewType === "Month" ? "contained" : "outlined"}
            >
              Month
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <IconButton onClick={handlePrev}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item>
            {viewType === "Week" || viewType === "Month" ? (
              <Typography variant="body1" gutterBottom>
                {dayjs(dateRange.startDate).format("YYYY-MM-DD")} to{" "}
                {dayjs(dateRange.endDate).format("YYYY-MM-DD")}
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                {dayjs(dateRange.startDate).format("YYYY-MM-DD")}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={handleNext}>
              <ArrowForward />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              fullWidth
            >
              <Button
                onClick={() => setFilter("imageResponse")}
                style={{
                  backgroundColor: filter === "imageResponse" ? "blue" : "",
                  color: filter === "imageResponse" ? "white" : "",
                  opacity: filter === "imageResponse" ? 1 : 0.5,
                }}
              >
                Image
              </Button>
              <Button
                onClick={() => setFilter("textAndImageResponse")}
                style={{
                  backgroundColor:
                    filter === "textAndImageResponse" ? "blue" : "",
                  color: filter === "textAndImageResponse" ? "white" : "",
                  opacity: filter === "textAndImageResponse" ? 1 : 0.5,
                }}
              >
                Text + Image
              </Button>
              <Button
                onClick={() => setFilter("textResponse")}
                style={{
                  backgroundColor: filter === "textResponse" ? "blue" : "",
                  color: filter === "textResponse" ? "white" : "",
                  opacity: filter === "textResponse" ? 1 : 0.5,
                }}
              >
                Text
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 450,
                  maxHeight: 450,
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <>
                {viewType === "Day" ? renderDayChart(chartData) : renderChart()}
              </>
            )}
          </Grid>
        </Grid>
      </ModalContainer>
    </Modal>
  );
};

export default GraphComponent;
