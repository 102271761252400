/** @format */

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Container,
  CircularProgress,
  Button,
} from "@mui/material";
import { memo, useRef } from "react";
import { getAllMeals } from "../services/userdetails.service";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

import { accountService } from "../services";
import { userService } from "../services/userdetails.service";
import { initialTargetValue } from "../utils/InitialValue";
import {
  calculateTotalCalorieAndProteinForWeekIndividually,
  getWeekFromDate,
  filterLastSevenDaysForSteps,
  filterLastSevenDaysWithMaxWaterValue,
  weekArrayDataOfWeights,
  getWeeklySleepData,
} from "../services/userdetails.service";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BasicInfoModal from "../modules/BasicInfoModal";
import Weight from "../modules/Weight";
import CurrentTargets from "../Components/UserDetails/CurrentTarget";
import UserDeviceInfo from "../Components/UserDetails/UserDeviceInfo";
import UserAppActivity from "../Components/UserDetails/UserAppActivity";
import UserBasicInfo from "../Components/UserDetails/UserBasicInfo";
import { userDetailsUtils } from "../utils/UserDetails";
import UserGraph from "../Components/UserDetails/UserGraph";
import { ta } from "date-fns/locale";
type Meal = {
  id: number;
  mealName: string;
  calorie: number;
  protein: number;
  quantity: number;
  units: string;
  dateTime: string;
  apiId: null | string;
};
interface UserDetails {
  selectedUser: any;
}

const UserDetails = ({ selectedUser }: UserDetails) => {
  const [loading, setLoading] = useState(false);
  const [mealData, setMealData] = useState<Meal | null>(null);
  const [meals, setMeals] = useState<any[]>([]);
  const [stepsWeekArray, setStepsWeekArray] = useState<any[]>([]);
  const [weightsWeekArray, setWeightsWeekArray] = useState<any[]>([]);
  const [waterWeekArray, setWaterWeekArray] = useState<any[]>([]);
  const [proteinTotals, setProteinTotals] = useState<any[]>([]);
  const [calorieTotals, setCalorieTotals] = useState<any[]>([]);
  const [sleepTotals, setSleepTotals] = useState<any[]>([]);
  const [currentWeight, setCurrentWeight] = useState<any>();
  const [weightUnit, setWeightUnit] = useState<any>();
  const [heightUnit, setHeightUnit] = useState<any>();
  const [open, setOpen] = useState(false);

  const [userTargets, setUserTargets] = React.useState({
    dateTime: "",
    id: 0,
    targetCalories: 0,
    targetGlasses: 0,
    targetProtein: 0,
    targetSleep: 0,
    targetSteps: 0,
    targetWeight: 0,
  });
  // Additional state for maxYValue
  const [maxValue, setMaxValue] = useState<any>();

  const [dataType, setDataType] = React.useState("steps");
  const [mainDataType, setMainDataType] = React.useState("");
  const [physicalData, setPhysicalData] = React.useState({
    steps: [
      {
        dateTime: "",
        steps: 0,
        day: "",
      },
    ],
    water: [
      {
        dateTime: "",
        water: 0,
      },
    ],
    weights: [
      {
        dateTime: "",
        weight: 0,
      },
    ],
    workouts: [],
  });

  const [dateRange, setDateRange] = useState({
    starting: "",
    ending: "",
  });
  const requestTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [targetLevels, setTargetLevels] = useState<{ [key: string]: number }>(
    initialTargetValue
  );
  const [bmr, setBmr] = React.useState<String | number>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function resetState() {
    setCurrentWeight(undefined);
    setWeightUnit(undefined);
    if (selectedUser.height !== null && selectedUser.height !== undefined) {
      setHeightUnit(selectedUser?.heightUnit === "cm" ? "cm" : "ft");
    } else {
      setHeightUnit(undefined);
    }

    setMeals([]);
    setMealData(null);
    setUserTargets({
      dateTime: "",
      id: 0,
      targetCalories: 0,
      targetGlasses: 0,
      targetProtein: 0,
      targetSleep: 0,
      targetSteps: 0,
      targetWeight: 0,
    });
    setDataType(mainDataType !== "" ? mainDataType : "steps");
    setCurrentDate(new Date());
    setDateRange({
      starting: "",
      ending: "",
    });
    setPhysicalData({
      steps: [
        {
          dateTime: "",
          steps: 0,
          day: "",
        },
      ],
      water: [
        {
          dateTime: "",
          water: 0,
        },
      ],
      weights: [
        {
          dateTime: "",
          weight: 0,
        },
      ],
      workouts: [],
    });
    setCalorieTotals([]);
    setWeightsWeekArray([]);
    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
    setTargetLevels(initialTargetValue);
  }

  useEffect(() => {
    resetState();
  }, [selectedUser]);

  const [latestActivityName, setLatestActivityName] = React.useState("");

  // Function to reset state on error
  function resetDataOnFetchError() {
    setCalorieTotals([]);
    setWeightsWeekArray([]);

    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
    setLoading(false);
  }

  ///Function For getting all the user Data including protein,calorie,steps and water-----------------/
  async function getAllUserData(
    mealsData: any,
    physicalData: any,
    currentDate: any,
    selectedUser: any
  ) {
    try {
      const weekArray = await getWeekFromDate(currentDate);

      const dateRange = await userDetailsUtils.getStartAndEndDate(weekArray);

      setDateRange(dateRange);
      const stepsWeekArray = await filterLastSevenDaysForSteps(
        physicalData?.steps,

        weekArray
      );

      const waterWeekArray = await filterLastSevenDaysWithMaxWaterValue(
        physicalData?.water,

        weekArray
      );

      const weightsWeekArray = await weekArrayDataOfWeights(
        physicalData?.weights,
        weekArray
      );

      const sleepWeekArray = await getWeeklySleepData(
        physicalData?.sleep,
        weekArray,
        selectedUser
      );

      const proteinTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "protein",
          weekArray
        );

      const calorieTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "calorie",
          weekArray
        );

      setCalorieTotals(calorieTotals);
      setProteinTotals(proteinTotals);
      setSleepTotals(sleepWeekArray);
      setWeightsWeekArray(weightsWeekArray);
      setStepsWeekArray(stepsWeekArray);
      setWaterWeekArray(waterWeekArray);
      setLoading(false);
      // Calculate maxYValue based on the current dataType
      const currentMaxValue = Math.max(
        ...stepsWeekArray.map((item) => item.steps)
      );
      const calculatedMaxYValue = Math.max(
        currentMaxValue,
        targetLevels[dataType] * 1.2
      );
      setMaxValue(calculatedMaxYValue);
    } catch (error) {
      resetDataOnFetchError();
    }
  }

  /// Function For making the api call of all meals and physcial activity---------------------------/
  async function dataApiCall(userId: string, selectedUser: any) {
    try {
      const meals = await getAllMeals(selectedUser?._id);

      const response = await userService.getCurrentWeight(selectedUser?._id);

      const generateBmr = await userDetailsUtils.calculateBMR(
        response.currentWeight,
        selectedUser.height,
        selectedUser.yob,
        selectedUser.gender
      );

      setBmr(generateBmr);
      setCurrentWeight(response.currentWeight);
      if (response.currentWeight !== "Not Available") {
        setWeightUnit(selectedUser.weightUnit);
      } else {
        setWeightUnit("");
      }
      setMeals(meals);

      const physicalData = await accountService.getUserPhysicalActivity(
        selectedUser?._id
      );
      setPhysicalData(physicalData);

      let date = new Date();
      getAllUserData(meals, physicalData, date, selectedUser);

      const latestActivityResult = await accountService.latestActivity(
        selectedUser?._id
      );
      const fetchedData = JSON.parse(latestActivityResult.data);
      setCurrentDate(new Date());
      setMealData(fetchedData[0]);
      setLatestActivityName(latestActivityResult.tableName);

      const targetsResult = await accountService.getTargets(selectedUser?._id);

      setUserTargets(targetsResult);
      setTargetLevels((prevLevels) => ({
        ...prevLevels,
        calorie: targetsResult.targetCalories,
        protein: targetsResult.targetProtein,
        water: targetsResult.targetGlasses,
        sleep: targetsResult.targetSleep,
        weights: targetsResult.targetWeight,
        steps: targetsResult.targetSteps,
      }));
      setLoading(false);
    } catch (error) {
      setCurrentDate(new Date());
      resetDataOnFetchError();
    }
  }
  React.useEffect(() => {
    // Clears the existing timeout if the effect is called again within 1 second
    if (requestTimeoutRef.current) {
      clearTimeout(requestTimeoutRef.current);
    }

    requestTimeoutRef.current = setTimeout(() => {
      if (selectedUser._id !== "") {
        setLoading(true);
        dataApiCall(selectedUser._id, selectedUser);
      }
    }, 1000); // Sets a 1-second delay for the operation

    // Cleanup function to clear the timeout when the component unmounts or before the effect runs again
    return () => {
      if (requestTimeoutRef.current) {
        clearTimeout(requestTimeoutRef.current);
      }
    };
  }, [selectedUser]);
  // Calculate the maximum Y-axis value (double the target value for the current dataType)
  const maxYValue = targetLevels[dataType] * 1.2;

  // Generate tick values
  const generateTickValues = (maxValue: any, numberOfTicks: any) => {
    const step = maxValue / (numberOfTicks - 1);
    return Array.from({ length: numberOfTicks }, (_, index) =>
      Math.round(index * step)
    );
  };

  const yAxisTicks = generateTickValues(maxYValue, 6); // Generate 6 tick values

  /// Function For handeling the weeks when pressed the arrow----------------/
  const handlePrevWeek = () => {
    const prevWeek = new Date(currentDate);

    prevWeek.setDate(currentDate.getDate() - 7);

    setCurrentDate(prevWeek);

    getAllUserData(meals, physicalData, prevWeek, selectedUser);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);
    setCurrentDate(nextWeek);
    getAllUserData(meals, physicalData, nextWeek, selectedUser);
  };

  const handleTypeChange = (
    event: any,
    newType: React.SetStateAction<string> | null
  ) => {
    if (newType !== null) {
      setMainDataType(newType);

      // Avoid unselecting all buttons
      setDataType(newType);
    }
  };

  return (
    <Box sx={{ height: "87vh", overflowY: "auto" }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              overflowY: "auto",
            }}
          >
            <UserBasicInfo
              selectedUser={selectedUser}
              currentWeight={currentWeight}
              bmr={bmr}
              handleOpen={handleOpen}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              "@media (max-width: 1280px)": {
                height: "410px",
              },
              "@media (max-width: 500px)": {
                height: "290px",
              },
            }}
          >
            {userTargets ? (
              <CurrentTargets loading={loading} userTargets={userTargets} />
            ) : null}
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 460 }}>
            <UserGraph
              loading={loading}
              dataType={dataType}
              dateRange={dateRange}
              currentDate={currentDate}
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              handleTypeChange={handleTypeChange}
              userTargets={userTargets}
              selectedUser={selectedUser}
              targetLevels={targetLevels}
              maxYValue={maxYValue}
              yAxisTicks={yAxisTicks}
              stepsWeekArray={stepsWeekArray}
              weightsWeekArray={weightsWeekArray}
              proteinTotals={proteinTotals}
              calorieTotals={calorieTotals}
              sleepTotals={sleepTotals}
              waterWeekArray={waterWeekArray}
              maxValue={maxValue}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ overflowY: "auto", height: 230 }}>
            <UserAppActivity
              loading={loading}
              mealData={mealData}
              meals={meals}
              latestActivityName={latestActivityName}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 230 }}>
            <UserDeviceInfo selectedUser={selectedUser} />
          </Card>
        </Grid>
      </Grid>
      <BasicInfoModal
        open={open}
        onClose={handleClose}
        selectedUser={selectedUser}
      />
    </Box>
  );
};

export default memo(UserDetails);
