/** @format */

const prod = Object.freeze({
  apiUrl: "https://reshape-backend.azurewebsites.net",
});

const dev = Object.freeze({
  apiUrl: "https://reshape-support-backend.azurewebsites.net",
  // apiUrl: "http://localhost:7071",
});

// export const config = process.env.NODE_ENV === "development" ? dev : prod;
export const config = dev;
