/** @format */

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import { settingsService } from "../../services/settings.service";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface FeatureLimits {
  logByImage: number;
  logByAudio: number;
  messagesToFio: number;
  singleWorkoutGeneration: number;
  workoutPlanGeneration: number;
}

interface SubscriptionSettingsInterface {
  country: string;
  subscriptionEnabled: boolean;
  featureLimits: FeatureLimits;
  initialQuota: number;
  trialPeriod: number;
  subscriptionMandatory?: boolean;
}

const subscriptionSettingsValidationSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  subscriptionEnabled: Yup.boolean().required(
    "Subscription Enabled is required"
  ),
  featureLimits: Yup.object().shape({
    logByImage: Yup.number().required("Log By Image is required"),
    logByAudio: Yup.number().required("Log By Audio is required"),
    messagesToFio: Yup.number().required("Messages To Fio is required"),
    singleWorkoutGeneration: Yup.number().required(
      "Single Workout Generation is required"
    ),
    workoutPlanGeneration: Yup.number().required(
      "Workout Plan Generation is required"
    ),
  }),
  initialQuota: Yup.number().required("Initial Quota is required"),
  trialPeriod: Yup.number().required("Trial Period is required"),
});

function SubscriptionSettings() {
  const [loading, setLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<
    SubscriptionSettingsInterface[]
  >([]);
  const [expanded, setExpanded] = useState<number | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function fetchSubscriptionSettings() {
    try {
      const subscriptionResponse =
        await settingsService.getSubscriptionSettings();

      setSubscriptionDetails(subscriptionResponse);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchSubscriptionSettings();
  }, []);

  async function subscriptionUpdate(
    subscriptionData: SubscriptionSettingsInterface[]
  ) {
    setSubmitLoading(true);
    try {
      const updateResponse = await settingsService.updateSubscriptionSettings(
        subscriptionData
      );

      let { message, updatedData, status } = updateResponse;
      if (status === true) {
        setSubscriptionDetails(updatedData);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }
    } catch (error) {
      setSnackbarMessage("Error updating subscription settings");
      setSnackbarOpen(true);
    }
    setSubmitLoading(false);
  }

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleExpand = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  const renderField = (name: string, label: string, type: string = "text") => (
    <Field name={name}>
      {({ field, meta }: FieldProps<any>) => (
        <TextField
          {...field}
          label={label}
          type={type}
          variant="outlined"
          fullWidth
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched ? meta.error : ""}
          style={{ marginTop: 20 }}
        />
      )}
    </Field>
  );

  const renderCheckboxField = (name: string, label: string) => (
    <Field name={name}>
      {({ field, form, meta }: any) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={field.value}
              onChange={(e) => {
                form.setFieldValue(field.name, e.target.checked);
              }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
          }
          label={label}
        />
      )}
    </Field>
  );

  return (
    <>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <div>
          {subscriptionDetails.length ? (
            <Formik
              initialValues={{ subscriptionDetails }}
              validationSchema={Yup.object({
                subscriptionDetails: Yup.array().of(
                  subscriptionSettingsValidationSchema
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                subscriptionUpdate(values.subscriptionDetails);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {subscriptionDetails.map((detail, index) => (
                    <Paper
                      key={index}
                      sx={{
                        margin: "20px auto",
                        padding: "20px",
                        width: expanded === index ? "100%" : "100%",
                        transition: "width 0.3s",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {detail.country} Subscription Settings
                        <IconButton onClick={() => toggleExpand(index)}>
                          {expanded === index ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Typography>
                      {expanded === index && (
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {renderCheckboxField(
                              `subscriptionDetails.${index}.subscriptionEnabled`,

                              "Subscription Enabled"
                            )}
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {renderCheckboxField(
                              `subscriptionDetails.${index}.subscriptionMandatory`,

                              "Subscription Mandatory"
                            )}
                          </Grid>

                          {Object.keys(detail.featureLimits).map(
                            (key, subIndex) => (
                              <Grid item xs={12} sm={4} key={subIndex}>
                                {renderField(
                                  `subscriptionDetails.${index}.featureLimits.${key}`,
                                  `${key.replace(/([A-Z])/g, " $1")}`,
                                  "number"
                                )}
                              </Grid>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            {renderField(
                              `subscriptionDetails.${index}.initialQuota`,
                              "Initial Quota",
                              "number"
                            )}
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            {renderField(
                              `subscriptionDetails.${index}.trialPeriod`,
                              "Trial Period",
                              "number"
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  ))}
                  <Grid item xs={12}>
                    {submitLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        style={{ marginTop: 20 }}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Typography>No subscription details available</Typography>
          )}
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
}

export default SubscriptionSettings;
