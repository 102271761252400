import React from "react";
import { CardContent, Typography, CircularProgress } from "@mui/material";
interface CurrentTargets {
  loading: boolean;
  userTargets: {
    targetCalories: number;
    targetProtein: number;
    targetSteps: number;
    targetWeight: number;
    targetSleep: number;
  };
}
const TargetInfo = ({ label, value }: any) => (
  <Typography sx={{ mb: 1 }} color="text.secondary">
    {label}: {value !== undefined ? value : "Not available"}
  </Typography>
);

const CurrentTargets = ({ loading, userTargets }: CurrentTargets) => {
  const {
    targetCalories,
    targetProtein,
    targetSteps,
    targetWeight,
    targetSleep,
  } = userTargets;

  return (
    <CardContent sx={{ padding: "16px 16px 32px 16px" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ height: 5, width: 7, marginTop: 10 }} />
        </div>
      ) : (
        <>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            color="#000"
            gutterBottom
          >
            Current Targets
          </Typography>
          <TargetInfo
            label="Calories"
            value={targetCalories || "Not available"}
          />
          <TargetInfo
            label="Protein"
            value={targetProtein || "Not available"}
          />
          <TargetInfo label="Steps" value={targetSteps || "Not available"} />
          <TargetInfo label="Weight" value={targetWeight || "Not available"} />
          <TargetInfo label="Sleep" value={targetSleep || "Not available"} />
        </>
      )}
    </CardContent>
  );
};
export default CurrentTargets;
