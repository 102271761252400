/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Toolbar,
} from "@mui/material";
import logoImage from "../assets/reshape_logo_full.png";
import profileImage from "../assets/Profile Image.png";
import { accountService } from "../services";
import { useMsal } from "@azure/msal-react";
import { routeService } from "../services/route.service";
import { userUtils } from "../utils/userUtils";
interface TopAppBar {
  allUsers?: any;
  totalNumberOfUsers?: any;
}
const TopAppBar = ({ allUsers, totalNumberOfUsers }: TopAppBar) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [user, setUser] = useState<any>();
  const [permissions, setPermissions] = useState<Array<String>>([]);
  const [accountName, setAccountName] = useState("");
  const [imageError, setImageError] = React.useState(false);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const handleImageError = () => {
    setImageError(true); // Prevent further attempts to load the image
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();

    const user = await accountService.userValue;

    if (user.height) {
      console.log("went here");

      /// means user has extra values so he has logged in using email
      accountService.logout();
      navigate("/login");
    } else {
      /// means user do not have extra values so has has logged in with sso

      // instance.logoutRedirect({
      //   account: accounts[0], // Logout the currently signed-in account
      //   postLogoutRedirectUri: "/login", // Redirect to home ("/") after logout
      // });

      accountService.logout();
      navigate("/login");
    }
  };

  const handleClientDetailsPage = () => {
    handleClose();
    navigate("/summary");
  };

  const handleProfilePage = () => {
    handleClose();
    navigate("/profile");
  };

  const handleNotificationPage = () => {
    handleClose();
    navigate("/notification", {
      state: { users: allUsers, totalCount: totalNumberOfUsers },
    });
  };
  const handleDiscountCodePage = () => {
    console.log("this is called");

    handleClose();
    navigate("/discountcode");
  };
  const handlePlanPage = () => {
    handleClose();
    navigate("/plan");
  };
  const handleAppSettingsPage = () => {
    handleClose();
    navigate("/appsettings");
  };
  const handleNutritionLogsPage = () => {
    handleClose();
    navigate("/nutritionlogs");
  };
  const handleEmailSendPage = () => {
    handleClose();
    navigate("/emailsend", {
      state: { users: allUsers, totalCount: totalNumberOfUsers },
    });
  };
  const handleRecipePage = () => {
    handleClose();
    navigate("/recipes", {});
  };
  async function getMainUser() {
    const mainUser = await accountService.userValue;

    // Check if permissions are already in localStorage
    const storedPermissions = localStorage.getItem("permissions");

    if (storedPermissions) {
      console.log("already present");

      // If permissions are found in localStorage, parse and set them
      setPermissions(JSON.parse(storedPermissions));
    } else {
      console.log("fetched for new");

      // If permissions are not in localStorage, fetch them from the API
      const permissionResponse = await routeService.getDashboardPermission(
        mainUser.role
      );

      // Store the permissions in localStorage
      localStorage.setItem("permissions", JSON.stringify(permissionResponse));

      // Set permissions in state
      setPermissions(permissionResponse);
    }

    // Set the main user in state
    setUser(mainUser);
  }

  const handleapiMonitorPage = () => {
    handleClose();
    navigate("/apimonitor", {});
  };
  const handleCampaignPage = () => {
    handleClose();
    console.log("before navigating users");
    console.log(allUsers);

    navigate("/campaign", {
      state: { users: allUsers, totalCount: totalNumberOfUsers },
    });
  };

  useEffect(() => {
    getMainUser();
    userUtils.getStorageName().then((accountName) => {
      setAccountName(accountName);
    });
  }, []);

  const menuItems = [
    { id: "summary", label: "Summary", onClick: handleClientDetailsPage },
    { id: "profile", label: "Profile", onClick: handleProfilePage },
    {
      id: "notification",
      label: "Notification",
      onClick: handleNotificationPage,
    },
    {
      id: "discountcode",
      label: "Discount Code",
      onClick: handleDiscountCodePage,
    },
    { id: "emailsend", label: "Email", onClick: handleEmailSendPage },
    { id: "plans", label: "Plans", onClick: handlePlanPage },
    {
      id: "appsettings",
      label: "App Settings",
      onClick: handleAppSettingsPage,
    },
    { id: "recipes", label: "Recipes", onClick: handleRecipePage },
    { id: "apimonitor", label: "Api Monitor", onClick: handleapiMonitorPage },
    {
      id: "nutritionlogs",
      label: "Nutrition Logs",
      onClick: handleNutritionLogsPage,
    },
    {
      id: "campaign",
      label: "Campaign",
      onClick: handleCampaignPage,
    },
  ];

  const renderMenuItems = () => {
    if (user && permissions) {
      if (permissions.includes("full_access")) {
        // If the user is admin, render all menu items
        return menuItems.map((item) => (
          <MenuItem key={item.id} id={item.id} onClick={item.onClick}>
            {item.label}
          </MenuItem>
        ));
      } else {
        // If the user is not admin, render only menu items present in user's permissions
        return menuItems
          .filter((item) => permissions.includes(item.id))
          .map((item) => (
            <MenuItem key={item.id} id={item.id} onClick={item.onClick}>
              {item.label}
            </MenuItem>
          ));
      }
    } else {
      return null; // Or you can return a loading state or placeholder
    }
  };

  return (
    <Toolbar style={{ justifyContent: "space-between" }}>
      <img
        src={logoImage}
        alt="Logo"
        style={{ width: "200px" }}
        onClick={() => navigate("/")}
      />

      <Box>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="medium">
            {user && !imageError && (
              <Avatar
                sx={{ width: 44, height: 44 }}
                src={`https://${accountName}.blob.core.windows.net/user-data/${
                  user.id
                }/profile-pic?${user.sasToken}&t=${new Date().getTime()}`}
                onError={handleImageError} // Trigger if the image fails to load
              />
            )}
            {user && imageError && (
              <Avatar sx={{ width: 44, height: 44 }}>
                {user.name[0].toUpperCase()}{" "}
                {/* Show the user's initial as a fallback */}
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {renderMenuItems()}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Toolbar>
  );
};
export default TopAppBar;
