import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
import { accountService } from "./account.service";

const baseUrl = `${config.apiUrl}/api`;
export const configureService = {
  addConfigureData,
  getConfigureData,
  updateConfigurationJson,
  getConfigurationData,
  getConfigureJsonData,
  deleteData,
  updateConfigurationData,
  runLlmApi,
  fetchLlmGraphData,
};

async function addConfigureData(data: any): Promise<{
  operationType: string;
  message: string;
  success: boolean;
  data: any;
}> {
  console.log("this is data");
  console.log(data);

  return fetchWrapper.post(`${baseUrl}/add-llmconfigure`, {
    data: data,
  });
}

/// Funciton for handeling the api for calling llm api's manually --------------------/
async function runLlmApi(data: any) {
  return fetchWrapper.post(`${baseUrl}/update-llmlogs`, {
    ...data,
  });
}

//// Function for getting the configure data-------------------------------/
async function getConfigureData(pageNumber: any): Promise<{
  message: string;
  success: boolean;
  data: any;
  testLogData: any;
  testLogLength: any;
}> {
  return fetchWrapper.get(`${baseUrl}/get-llmconfigure?number=${pageNumber}`);
}

async function updateConfigurationJson(data: any): Promise<any> {
  // Create a FormData object
  const formData = new FormData();

  if (data.hint) formData.append("hint", data.hint);
  if (data.imageprompt) formData.append("imageprompt", data.imageprompt);
  if (data.textprompt) formData.append("textprompt", data.textprompt);
  // Append the file only if it exists
  if (data.image) {
    formData.append("image", data.image);
  }

  // Log FormData contents
  formData.forEach((value, key) => {
    console.log(key, value);
  });

  return fetch(`${baseUrl}/update-configuration-json`, {
    method: "post",
    body: formData,
  });
}

//// Function for getting the configure data-------------------------------/
async function getConfigurationData(): Promise<{
  message: string;
  success: boolean;
  data: any;
  testLogData: any;
}> {
  return fetchWrapper.get(`${baseUrl}/get-llmconfigurationdata`);
}

async function getConfigureJsonData() {
  // New: GET request to the specified endpoint
  const cachebuster = Math.floor(Math.random() * 200) + 1;
  const storageNameResponse = await accountService.getStorageName();
  const response = await fetch(
    `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/llm-settings.json?cachebuster=${cachebuster}`
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json(); // Assuming the response is JSON
  console.log("this is data");
  console.log(data);

  // Here you can combie or manipulate the data from both requests as needed
  // For this examnple, we're just returning the JSON data from the GET request
  return data;
}

//// function for deleting the configuration data------------------------------------------/
async function deleteData(id: string) {
  return fetchWrapper.delete(`${baseUrl}/delete-configurationdata?id=${id}`);
}

//// Function for updating the configuration data ---------------------------------------------/

async function updateConfigurationData(data: any) {
  return fetchWrapper.put(`${baseUrl}/update-configuration`, { data });
}

//// Function for getting the llm data for showing on the graph -----------------------------/
async function fetchLlmGraphData(dateRange: any, filter: any, viewType: any) {
  return fetchWrapper.post(`${baseUrl}/get-llm-graphdata`, {
    dateRange,
    filter,
    viewType,
  });
}
