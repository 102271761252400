/** @format */

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { settingsService } from "../../services/settings.service";
import { validationSchemaForPrompt } from "../../utils/AppSettings";
import { accountService } from "../../services/account.service";
function PromptSettings() {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    nutrition_prompt: "",
    nutrition_single_prompt: "",
    nutrition_multi_prompt: "",
    nutrition_vision_prompt: "",
    home_insights_prompt: "",
    single_workout_prompt: "",
    workout_plan_prompt: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const storageNameResponse = await accountService.getStorageName();
        const response = await fetch(
          `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/prompts.json`
        );
        const data = await response.json();
        setInitialValues({
          nutrition_prompt: data.nutrition_prompt,
          nutrition_single_prompt: data.nutrition_single_prompt,
          nutrition_multi_prompt: data.nutrition_multi_prompt,
          nutrition_vision_prompt: data.nutrition_vision_prompt,
          home_insights_prompt: data.home_insights_prompt,
          single_workout_prompt: data.single_workout_prompt,
          workout_plan_prompt: data.workout_plan_prompt,
        });
      } catch (error) {
        console.error("Failed to fetch prompts:", error);
        setSnackbarMessage("Failed to fetch prompts. Please try again later.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setSubmitLoading(true);
    try {
      const updateResponse = await settingsService.updatePromptSettings(values);
      let { message, updatedData, status } = updateResponse;
      if (status === true) {
        setInitialValues(updatedData);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      } else {
        setInitialValues(initialValues);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }
    } catch (error) {}
    setSubmitLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          Prompt Settings
        </Typography>
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaForPrompt}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3} justifyContent="center">
                {/* Form fields for each prompt */}
                {Object.keys(initialValues).map((key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <Field
                      as={TextField}
                      label={key.replace(/([A-Z])/g, " $1").trim()} // Enhances readability of labels
                      name={key}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  {submitLoading ? (
                    <CircularProgress></CircularProgress>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      style={{ marginTop: 20 }}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </Box>
  );
}

export default PromptSettings;
