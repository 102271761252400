import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
  Collapse,
  Grid,
  CircularProgress,
  Snackbar,
  Button,
  Checkbox,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  MultiDescriptionInterface,
  MultipleRecipeInterface,
  PromptsInterface,
  SingleRecipe,
} from "../../../interface/RecipeInterface";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import EmptyMultiRecipeCard from "./EmptyMultiRecipeCard";
import { recipeService } from "../../../services/recipe.service";
import RecipeImageModal from "../RecipeImageModa";
import { string } from "yup";
import MultiModal from "./MultiModal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { accountService } from "../../../services/account.service";
interface CategoryItem {
  index: number;
  category: string;
  recipeName: string;
}
//// Main Function For showing the MultiDescription ------------------------------------------------------------------->
const MultiDescription = ({
  multiRecipe,
  setMultiRecipe,
  setMultiRecipeLoading,
  multiRecipeLoading,
  prompts,
  setPrompts,
}: MultiDescriptionInterface) => {
  const [openId, setOpenId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [recipeImageModalOpen, setRecipeImageModalOpen] = useState(false);
  const [recipeId, setRecipeId] = useState("");
  const [recipeImage, setRecipeImage] = useState("");
  const [eachCardRecipeLoading, setEachCardRecipeLoading] = useState(false);
  const [eachCardImageLoading, setEachCardImageLoading] = useState(false);
  const [currentRecipeIndex, setCurrentRecipeIndex] = useState<number | null>(
    null
  );
  const [currentRecipe, setCurrentRecipe] = useState<SingleRecipe>();
  const [isCardOpen, setIsCardOpen] = useState(false);
  const handleCloseCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const [selectedRecipes, setSelectedRecipes] = React.useState<SingleRecipe[]>(
    []
  );

  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(
    null
  );
  const [categories, setCategories] = useState<{ [key: number]: string[] }>({});
  const [inputValues, setInputValues] = useState<{ [key: number]: string }>({});
  const [storageName, setStorageName] = useState<string | null>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValues, setEditValues] = useState({
    name: "",
    description: "",
  });
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const fetchedCategories = await recipeService.getUniqueCategories();
        setUniqueCategories(fetchedCategories.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    fetchCategories();
  }, []);
  const handleEdit = (index: number, recipe: SingleRecipe) => {
    setEditIndex(index);
    setEditValues({ name: recipe.name, description: recipe.description });
  };

  const handleSave = (index: number) => {
    if (multiRecipe) {
      const updatedRecipes = [...multiRecipe];
      updatedRecipes[index] = {
        ...updatedRecipes[index],
        name: editValues.name,
        description: editValues.description,
      };
      setMultiRecipe(updatedRecipes);
      setEditIndex(null);
    }
  };

  const handleCategoryChange = (
    index: number,
    event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [index]: value,
    }));
  };

  // Handle input change for each index
  const handleInputChange = (
    index: number,
    event: any,
    newInputValue: string
  ) => {
    if (newInputValue.includes(",")) {
      const newCategory = newInputValue.replace(",", "").trim(); // Remove the comma and trim spaces

      if (newCategory && !categories[index]?.includes(newCategory)) {
        setCategories((prevCategories) => ({
          ...prevCategories,
          [index]: [...(prevCategories[index] || []), newCategory],
        }));
      }

      // Clear input for the current index after adding the category
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [index]: "",
      }));
    } else {
      // Update the input value for the current index
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [index]: newInputValue,
      }));
    }
  };

  const handleCheckboxChange = (recipe: SingleRecipe) => {
    setSelectedRecipes((prevSelectedRecipes) => {
      const isSelected = prevSelectedRecipes.some(
        (r) => r.name === recipe.name
      );
      if (isSelected) {
        return prevSelectedRecipes.filter((r) => r.name !== recipe.name);
      } else {
        return [...prevSelectedRecipes, recipe];
      }
    });
  };

  const closeRecipeImageModal = () => {
    setRecipeImageModalOpen(!closeRecipeImageModal);
  };
  //// Funciton for opening up the image modal ----------------------------------------------------/
  function openImage(recipeId: string, recipeImage: string) {
    setRecipeId(recipeId);
    setRecipeImageModalOpen(!recipeImageModalOpen);
    setRecipeImage(recipeImage);
  }
  const handleClick = (id: any) => {
    setOpenId(openId === id ? null : id);
  };
  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  /// Function for saving the recipe in the saved database---------------------------------/
  async function addRecipes(dbType: string, recipeDetails: any) {
    try {
      if (selectedRecipes.length === 0) {
        alert("Select the recipe first");
      } else {
        if (Object.keys(categories).length === 0) {
          setSnackbarOpen(true);
          setSnackbarMessage("Any categories can't be empty");
        } else {
          setButtonLoading(true);
          try {
            let addRecipePromises = selectedRecipes.map(
              (recipe: any, index: number) => {
                const categoryValues = categories[index] || [];

                if (categoryValues.length === 0) {
                  throw new Error("Categories can't be empty");
                } else {
                  return recipeService.addRecipes(dbType, {
                    ...recipe,
                    categories: categoryValues, // Use selected categories array
                  });
                }
              }
            );

            Promise.all(addRecipePromises)
              .then((responses) => {
                let allSuccesses = responses.every(
                  (response) => response.success
                );

                if (allSuccesses) {
                  setSnackbarMessage("Recipes have been saved");
                  setSnackbarOpen(true);
                  setButtonLoading(false);
                } else {
                  setSnackbarMessage("Unable to save the recipes");
                  setSnackbarOpen(true);
                  setButtonLoading(false);
                }
              })
              .catch((error: any) => {
                console.log(error.message);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);
                setButtonLoading(false);
              });
          } catch (error: any) {
            console.log(error.message);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);
            setButtonLoading(false);
          }
        }
      }
    } catch (error) {
      setSnackbarMessage("Categories can't be empty");
      setSnackbarOpen(true);
    }
  }

  /// Function for refreshing any card image -----------------------------------------------------/
  async function refreshAnyCardImage(
    recipeName: string,
    index: number,
    prompts: PromptsInterface
  ) {
    setEachCardImageLoading(true);
    setCurrentImageIndex(index);
    try {
      // Split the gpt string into an array of recipe details
      const recipeDetailsArray = prompts.recipeDetails.split(",");
      /// checking if input value of recipe details is equal to the card number or not ------------------------------/
      if (multiRecipe?.length === recipeDetailsArray.length) {
        // Check if the index is within the bounds of the array
        if (index >= 0 && index < recipeDetailsArray.length) {
          /// doing this so when in input there are multiplerecipes we can identify for the refresh which recipe input should we use
          /// means if we are refreshing the second recipe details then it should be refreshed based on the second input
          const selectedRecipeDetail = recipeDetailsArray[index].trim(); // Get

          let dalleImagePrompt = `${prompts.dalle} Recipe Details is ${selectedRecipeDetail}`;
          let promptValue = [];
          promptValue.push(dalleImagePrompt);

          localStorage.setItem("multiDalleImagePrompt", prompts.dalle);
          const getDalleImage = await recipeService.getImageForDalle(
            promptValue,
            "single"
          );

          let newImage = getDalleImage.recipeImage[0];
          // Update the recipe's image whose index matches
          if (multiRecipe !== null) {
            for (let i = 0; i < multiRecipe.length; i++) {
              multiRecipe[index].image = newImage;
            }
            setMultiRecipe(multiRecipe);
          }
          setCurrentImageIndex(null);

          setSnackbarOpen(true);
          setSnackbarMessage("New recipe image has been generated");
        } else {
          console.error("Index out of bounds for recipe details array");
          setSnackbarOpen(true);
          setSnackbarMessage("Invalid recipe index");
        }
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(
          "Recipe details input should be equal with recipe number"
        );
      }
    } catch (error) {
      setEachCardImageLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to generate new image");
    }
    setEachCardImageLoading(false);
  }

  /// Function for refreshing any card recipe info details -----------------------------------------------------/
  async function refreshAnyCardRecipes(
    recipeName: string,
    index: number,
    prompts: PromptsInterface
  ) {
    setEachCardRecipeLoading(true);
    setCurrentRecipeIndex(index);
    try {
      // Split the gpt string into an array of recipe details
      const recipeDetailsArray = prompts.recipeDetails.split(",");
      /// checking if input value of recipe details is equal to the card number or not ------------------------------/
      if (multiRecipe?.length === recipeDetailsArray.length) {
        // Check if the index is within the bounds of the array
        if (index >= 0 && index < recipeDetailsArray.length) {
          /// doing this so when in input there are multiplerecipes we can identify for the refresh which recipe input should we use
          /// means if we are refreshing the second recipe details then it should be refreshed based on the second input
          const selectedRecipeDetail = recipeDetailsArray[index].trim(); // Get

          let gptPrompt = `- Input User query is provided to you as "recipe details"\n${prompts.gpt}`;
          localStorage.setItem("multiChatGptPrompt", gptPrompt);

          const getGptResponse = await recipeService.getSingleRecipesFromAi(
            gptPrompt,
            selectedRecipeDetail
          );
          // Update the recipe's fields whose index matches, except for the image
          if (multiRecipe !== null) {
            let updatedRecipes = [...multiRecipe];
            // Use a for loop to find the specific recipe by index and update its fields
            for (let i = 0; i < updatedRecipes.length; i++) {
              if (i === index) {
                updatedRecipes[i] = {
                  ...updatedRecipes[i],
                  ...getGptResponse.recipeDetails,
                  image: updatedRecipes[i].image, // Preserve the existing image
                };
              }
            }
            // Set the updated state
            setMultiRecipe(updatedRecipes);
          }

          setSnackbarOpen(true);
          setSnackbarMessage("New recipe  has been generated");
        } else {
          console.error("Index out of bounds for recipe details array");
          setSnackbarOpen(true);
          setSnackbarMessage("Invalid recipe index");
        }
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(
          "Recipe details input should be equal with recipe number"
        );
      }
    } catch (error) {
      setEachCardRecipeLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to generate recipe");
    }
    setEachCardRecipeLoading(false);
  }

  function openCard(recipe: any) {
    setCurrentRecipe(recipe);
    setIsCardOpen(true);
  }
  async function fetchStorageName() {
    const storageNameResponse = await accountService.getStorageName();
    setStorageName(storageNameResponse.storageName);
  }
  useEffect(() => {
    fetchStorageName();
  }, []);
  return (
    <>
      {multiRecipeLoading ? (
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          {multiRecipe && multiRecipe.length > 0 ? (
            <>
              {" "}
              {multiRecipe.map(
                (recipe: MultipleRecipeInterface, index: number) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",

                      margin: "20px 0",
                      overflow: "auto",
                    }}
                  >
                    <Card
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
                        position: "relative",
                        zIndex: 2,
                        margin: "20px",
                        height: "55vh",
                        backgroundColor: "#f5f5f5",
                        overflowY: "scroll",
                      }}
                    >
                      <CardContent
                        style={{ padding: "20px", borderRadius: "10px" }}
                      >
                        <Checkbox
                          checked={selectedRecipes.some(
                            (r) => r.name === recipe.name
                          )}
                          onChange={() => handleCheckboxChange(recipe)}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {eachCardImageLoading &&
                          currentImageIndex === index ? (
                            <Container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30vh",
                              }}
                            >
                              <CircularProgress />
                            </Container>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  width: "35%",
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "30vh",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <img
                                    src={
                                      recipe.image
                                        ? recipe.image
                                        : `https://${storageName}.blob.core.windows.net/reshape-public/reshape-public/recipes/images/saved/${recipe.id}.png`
                                    }
                                    alt="Recipe"
                                    style={{
                                      width: "100%",
                                      borderRadius: 4,
                                      height: "70%",
                                      cursor: "pointer",
                                    }}
                                    loading="lazy"
                                    onClick={() =>
                                      openImage(recipe.id, recipe.image)
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <RefreshIcon
                                    onClick={() => {
                                      refreshAnyCardImage(
                                        recipe.name,
                                        index,
                                        prompts
                                      );
                                    }}
                                    sx={{ marginTop: 20, cursor: "pointer" }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}

                          {eachCardRecipeLoading &&
                          currentRecipeIndex === index ? (
                            <Container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                widht: "65%",
                              }}
                            >
                              <CircularProgress />
                            </Container>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "65%",
                                  marginLeft: 3,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box>
                                    {editIndex === index ? (
                                      <>
                                        <TextField
                                          label="Recipe Name"
                                          value={editValues.name}
                                          onChange={(e) =>
                                            setEditValues({
                                              ...editValues,
                                              name: e.target.value,
                                            })
                                          }
                                        />
                                        <TextField
                                          label="Description"
                                          value={editValues.description}
                                          onChange={(e) =>
                                            setEditValues({
                                              ...editValues,
                                              description: e.target.value,
                                            })
                                          }
                                          sx={{ marginTop: 2 }}
                                        />
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => handleSave(index)}
                                        >
                                          <CheckIcon />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          fontWeight="bold"
                                        >
                                          {recipe.name}

                                          <IconButton
                                            onClick={() =>
                                              handleEdit(index, recipe)
                                            }
                                          >
                                            <EditIcon sx={{ fontSize: 15 }} />
                                          </IconButton>
                                          <RefreshIcon
                                            sx={{
                                              marginLeft: 1,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              refreshAnyCardRecipes(
                                                recipe.name,
                                                index,
                                                prompts
                                              );
                                            }}
                                          />
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {recipe.description}
                                        </Typography>
                                      </>
                                    )}
                                  </Box>

                                  <Typography
                                    variant="body1"
                                    sx={{ textAlign: "start", width: "100%" }}
                                  >
                                    Duration: {recipe.duration}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginTop: "10px",
                                  }}
                                >
                                  {[
                                    {
                                      key: "protein",
                                      label: "Protein",
                                      unit: "g",
                                    },
                                    { key: "carbs", label: "Carbs", unit: "g" },
                                    { key: "fats", label: "Fat", unit: "g" },
                                    {
                                      key: "calories",
                                      label: "Calories",
                                      unit: "kcal",
                                    }, // Calories typically do not need 'g' unit
                                  ].map((nutrient) => (
                                    <Card
                                      key={nutrient.key}
                                      sx={{
                                        padding: "10px",
                                        margin: "5px",
                                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                        backgroundColor: "#e3f2fd",
                                        width: "22%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          textAlign: "center",
                                          "@media (max-width: 1290px)": {
                                            fontSize: 10, // Apply bold weight when the screen width is below 1290px
                                          },
                                        }}
                                      >
                                        {nutrient.label}: {recipe[nutrient.key]}
                                        {nutrient.unit}
                                      </Typography>
                                    </Card>
                                  ))}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: 3,
                            flexDirection: "row",

                            width: "100%",
                          }}
                        >
                          <Autocomplete
                            multiple
                            freeSolo
                            options={uniqueCategories}
                            value={categories[index] || []}
                            inputValue={inputValues[index] || ""} // Manage input value for each index
                            sx={{ width: "100%" }}
                            onChange={(event, newValue) =>
                              handleCategoryChange(index, event, newValue)
                            }
                            onInputChange={(event, newInputValue) =>
                              handleInputChange(index, event, newInputValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                placeholder="Select or type categories"
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </div>
                )
              )}
              {buttonLoading ? (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 30,
                  }}
                >
                  <CircularProgress />
                </Container>
              ) : (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={() => addRecipes("saved", multiRecipe)}
                    disabled={multiRecipe?.length === 0}
                  >
                    Add to Saved
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => addRecipes("prod", multiRecipe)}
                    disabled={multiRecipe?.length === 0}
                  >
                    Add to Prod
                  </Button>
                </>
              )}
            </>
          ) : (
            <EmptyMultiRecipeCard />
          )}
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
      <RecipeImageModal
        recipeImageModalOpen={recipeImageModalOpen}
        closeRecipeImageModal={closeRecipeImageModal}
        recipeId={recipeId}
        setRecipeImageModalOpen={setRecipeImageModalOpen}
        recipeImage={recipeImage}
        storageName={storageName}
      />
      {currentRecipe ? (
        <MultiModal
          isCardOpen={isCardOpen}
          handleCloseCard={handleCloseCard}
          recipe={currentRecipe}
          storageName={storageName}
        />
      ) : null}
    </>
  );
};

export default MultiDescription;
